var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-page" }, [
    _c("h1", [_vm._v("Setup some Google Fonts locally.")]),
    _vm._v(" "),
    _c("div", [
      _c("label", { attrs: { for: "google_font_css" } }, [
        _vm._v("Google Fonts embed URL"),
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.css,
            expression: "css",
          },
        ],
        attrs: { id: "google_font_css" },
        domProps: { value: _vm.css },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.css = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-actions" }, [
      _c(
        "button",
        {
          attrs: { disabled: _vm.working, type: "button" },
          on: { click: _vm.submit },
        },
        [_vm._v("Gimme the thing")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }