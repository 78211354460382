<template>
    <div class="vue-page">
        <div class="vue-page__inner">
            <h1>URL Downloader</h1>

            <section>
                <label for="urls">Enter URLs, one per line</label>
                <textarea id="urls" v-model="items"></textarea>
            </section>

            <div>
                <button type="button" @click="submit">Download all</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: "",
            };
        },

        methods: {
            submit() {
                this.$axios.post("/api/download-all/index", {items: this.items}).then(r => console.log(r.data))
            }
        },

        computed: {
        },

        created() {
        },
    };
</script>

<style lang="less" scoped>
    .vue-page,
    .vue-page__inner {
        display: flex;
        flex-direction: column;
        font-family: Cabin, sans-serif;
        box-sizing: border-box;
    }

    .vue-page {
        min-height: 100vh;
        padding: 3rem;
        background: transparent linear-gradient(to top left, yellowgreen, yellow);
    }

    .vue-page__inner {
        background-color: #000;
        color: yellowgreen;
        justify-content: center;
        align-items: center;
        flex: 1;
    }

    section {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin: 3rem 0;
    }

    textarea {
        min-width: 640px;
        min-height: 320px;
        border-radius: 0.25rem;
        border: 0 none;
    }

    button {
        background: transparent linear-gradient(to top left, yellowgreen, yellow);
        background-position: fixed;
        padding: 2rem;
        font-size: 1.5rem;
        border-radius: 0.25rem;
        border: 0 none;
        font-weight: bold;
        text-transform: uppercase;
        color: rgb(0 0 0 / 0.5);
        cursor: pointer;

        &:hover {
            color: #000;
        }
    }
</style>
