var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app" }, [
    _c(
      "div",
      { staticClass: "menu", attrs: { id: "vue-menu--base64" } },
      [_c("main-menu", { attrs: { open: true } })],
      1
    ),
    _vm._v(" "),
    _c("h1", { staticClass: "app__title" }, [_vm._v("Base 64 encoder")]),
    _vm._v(" "),
    _c("div", { staticClass: "app__body" }, [
      _c(
        "button",
        { staticClass: "app__flip-button", on: { click: _vm.flip } },
        [_c("RefreshIcon", { staticClass: "icon-16" })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "encoder__group encoder__group--encoded",
          style: { order: _vm.source === "encoded" ? -1 : 0 },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.encoded,
                expression: "encoded",
              },
            ],
            staticClass: "encoder__input",
            attrs: {
              type: "text",
              name: "encoded",
              disabled: _vm.source !== "encoded",
              placeholder: "Base 64 text here...",
            },
            domProps: { value: _vm.encoded },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.encoded = $event.target.value
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "encoder__group encoder__group--decoded",
          style: { order: _vm.source === "decoded" ? -1 : 0 },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.decoded,
                expression: "decoded",
              },
            ],
            staticClass: "encoder__input",
            attrs: {
              type: "text",
              name: "decoded",
              disabled: _vm.source !== "decoded",
              placeholder: "Plain text here...",
            },
            domProps: { value: _vm.decoded },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.decoded = $event.target.value
              },
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }