/*
 * The "clip" directive can be added to elements to copy a piece of text to the clipboard
 * when the element is clicked.
 *
 * The binding supports a special property, "value", that will be copied to the clipboard;
 * if no "value" is provided, the binding operates on the textContent of the element.
 *
 * Adding the `.select` modifier will select the text inside the element after copying.
 *
 * ```js
 * <div v-clip="Copied text">           Other text                  </div>
 * <div v-clip.select="Copied text">    Selected text               </div>
 * <div v-clip.select>                  Copied and selected text    </div>
 * ```
 */
export default function (el, binding) {
    el.addEventListener("click", function () {
        let t = binding.value ? binding.value : el.textContent;

        {
            // create a new hidden node, add the selected text to it, and call
            // the "copy" command so the text is copied to the clipboard

            const newNode = document.createElement("div");
            newNode.textContent = t;
            newNode.style.position = "absolute";
            newNode.style.opacity = "0";

            document.body.appendChild(newNode);

            const selection = window.getSelection();
            const range = document.createRange();

            range.selectNodeContents(newNode);
            selection.removeAllRanges();
            selection.addRange(range);

            document.execCommand("copy");

            document.body.removeChild(newNode);
        }


        // select the text content of the clicked element
        if (binding.modifiers.select) {
            const selection = window.getSelection();
            const range = document.createRange();

            selection.removeAllRanges();
            range.selectNodeContents(el);
            selection.addRange(range);
        }
    });
};
