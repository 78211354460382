<template>
    <div class="vue-page gradients-page">
        <div class="card" :style="boxStyles"></div>

        <div class="form">
            <pre><code>.card {
    background-image: {{boxStyles.backgroundImage}}
}</code></pre>

            <div class="steps" :style="fancyGradient"></div>

            <h4>Linear gradient</h4>

            <div class="color-input">
                <label for="linearGradient_fromColor">From color:</label> <input type="text" v-model="linearGradient.fromColor" id="linearGradient_fromColor">
                <div class="swatch" :style="{backgroundColor: linearGradient.fromColor}"></div>
            </div>

            <div class="color-input">
                <label for="linearGradient_toColor">To color:</label> <input type="text" v-model="linearGradient.toColor" id="linearGradient_toColor">
                <div class="swatch" :style="{backgroundColor: linearGradient.toColor}"></div>
            </div>

            <div class="angle-input">
                <label for="linearGradient_angle">Angle:</label> <input type="text" v-model="linearGradient.angle" id="linearGradient_angle">
            </div>

            <h4>Radial gradient</h4>

            <div class="color-input">
                <label for="radialGradient_fromColor">From color:</label> <input type="text" v-model="radialGradient.fromColor" id="radialGradient_fromColor">
                <div class="swatch" :style="{backgroundColor: radialGradient.fromColor}"></div>
            </div>

            <div class="color-input">
                <label for="radialGradient_toColor">To color:</label> <input type="text" v-model="radialGradient.toColor" id="radialGradient_toColor">
                <div class="swatch" :style="{backgroundColor: radialGradient.toColor}"></div>
            </div>

            <div class="position-input">
                <label for="radialGradient_position">Angle:</label> <input type="text" v-model="radialGradient.position" id="radialGradient_position">
            </div>
        </div>
    </div>
</template>

<script>
    import Color from "color";
    
    export default {
        data() {
            return {
                linearGradient: {
                    fromColor: "#3589ff",
                    toColor: "#34d055",
                    angle: 45,
                },
                radialGradient: {
                    fromColor: "rgba(255, 0, 255, 0.25)",
                    toColor: "rgba(255, 255, 0, 0.2)",
                    position: "top left",
                },
            };
        },

        computed: {
            boxStyles() {
                return {
                    backgroundImage: `radial-gradient(ellipse at ${this.radialGradient.position}, ${this.radialGradient.fromColor}, ${this.radialGradient.toColor}), linear-gradient(${this.linearGradient.angle}deg, ${this.linearGradient.fromColor}, ${this.linearGradient.toColor})`,
                };
            },

            steps() {
                const STEP_COUNT = 5;
                const from = new Color(this.linearGradient.fromColor).hwb();
                const to = new Color(this.linearGradient.toColor).hwb();
                const diff = to.hue() - from.hue();
                const step = diff / STEP_COUNT;
                let mix = from;

                const result = [];

                result.push(from.hex());

                for (let i = 0; i < STEP_COUNT; i++) {
                    mix = mix.rotate(step);
                    result.push(mix.hex());
                }

                result.push(to.hex());

                return result;
            },

            fancyGradient() {
                return {
                    backgroundColor: "yellow",
                    height: "75px",
                };
            }
        },
    };
</script>

<style lang="less" scoped>
    .gradients-page {
        display: flex;
        font-family: sans-serif;
        padding: 3rem;
    }

    pre {
        font-size: 0.8rem;
        background-color: #f0f0f0;
        font-family: consolas, monospace;
        padding: 0.5rem;
    }

    .card {
        margin: 24px;
        width: 200px;
        height: 200px;
        border-radius: 10px;
        flex: 0 0 200px;

        // background: radial-gradient(ellipse at top left, rgba(255, 255, 0, 0.25), rgba(0, 255, 255, 0.5)), linear-gradient(45deg, #34589f, #349055);
    }

    .form {
        border: 1px solid #f0f0f0;
        margin-left: 4rem;
        padding: 1rem;

        h4:first-child {
            margin-top: 0;
        }
    }

    .color-input,
    .angle-input,
    .position-input {
        padding-left: 3rem;
        min-height: 32px;
        margin-bottom: 0.25rem;

        label {
            min-width: 80px;
            display: inline-block;
        }

        input[type=text] {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 2px;
        }
    }

    .color-input {
        position: relative;

        .swatch {
            width: 32px;
            height: 32px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
</style>
