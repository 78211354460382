var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.prefix + "--main-menu" },
    [
      _c("div", { staticClass: "menu__toggle", on: { click: _vm.toggle } }, [
        _c("span"),
        _c("span"),
        _c("span"),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "app_menu" } }, [
        _vm.isOpen
          ? _c(
              "div",
              { staticClass: "menu__list" },
              _vm._l(_vm.menuItems, function (item) {
                return _c(
                  "a",
                  { staticClass: "menu__item", attrs: { href: item.url } },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }