var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "color-pair-grid" },
      _vm._l(_vm.colorPairs, function (c) {
        return _c("color-pair", {
          key: c.join(),
          attrs: { colors: c, gradient: _vm.showGradients },
        })
      }),
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showOptions,
            expression: "showOptions",
          },
        ],
        staticClass: "options",
      },
      [
        _c("label", { staticClass: "option" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.showGradients,
                expression: "showGradients",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.showGradients)
                ? _vm._i(_vm.showGradients, null) > -1
                : _vm.showGradients,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.showGradients,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.showGradients = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.showGradients = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.showGradients = $$c
                }
              },
            },
          }),
          _vm._v(" Show gradients\n        "),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }