<template>
    <div class="vue-page">
        <h1>ifcanduela.com</h1>

        <div class="app-grid">
            <a class="app card" v-for="app in apps" :href="app.url">
                <div class="card__thumbnail">
                    <img :src="app.thumbnail" :alt="app.name">
                </div>
                <h2 class="card__title">{{app.name}}</h2>
                <div class= "card__description">{{app.description}}</div>
            </a>

            <a class="app card card--no-image" v-for="app in appsies" :href="app.url">
                <h2 class="card__title">{{app.name}}</h2>
                <div class= "card__description">{{app.description}}</div>
            </a>

            <a class="app card card--no-image" href="https://pew.ifcanduela.com/">
                <h2 class="card__title">Pew-pew-pew</h2>
            </a>

            <a class="app card card--no-image" href="https://www.github.com/ifcanduela/webpack-boilerplate">
                <h2 class="card__title">Webpack boilerplate</h2>
            </a>
        </div>
    </div>
</template>

<script>
    const baseUrl = document.querySelector("meta[name=base-url]").content;

    export default {
        data() {
            return {
                apps: [
                    {name: "The Beach", url: "http://local.pg", description: "Photo collection", thumbnail: "/assets/app-thumbnails/beach.png"},
                    {name: "Atropos", url: "http://trophies.local", description: "Trophy tracker", thumbnail: "/assets/app-thumbnails/trophies.png"},
                    {name: "Duels", url: "http://duels.local", description: "Illustrations album", thumbnail: "/assets/app-thumbnails/duels.png"},
                ],
                appsies: [
                    {name: "Wishlist", url: `${baseUrl}/wishlist`, description: "Playstation Wishlist", thumbnail: "/assets/app-thumbnails/wishlist.png"},
                    {name: "Dottie", url: `${baseUrl}/dottie`, description: "Dottie", thumbnail: "/assets/app-thumbnails/dottie.png"},
                ],
            };
        },

        methods: {
        },

        computed: {
        },
    };
</script>

<style lang="less" scoped>
    @import url("https://fonts.googleapis.com/css2?familiy=Roboto+Condensed&family=Merriweather:ital@1&display=swap");

    .vue-page {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2392aca7' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    }

    h1 {
        font-family: "Roboto Condensed", sans-serif;
    }

    .app-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
        width: 1200px;
    }

    .card {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.95);
        border-radius: 0.5rem;
        background-color: #fff;
        box-shadow: 0 2px 15px #0001;

        &__thumbnail {
            display: block;
            flex: 0 0 180px;
            margin: 0 0 1rem;
            border-radius: 0.5rem 0.5rem 0 0;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1.0);
                transition: transform 1s cubic-bezier(0, 1, 0.5, 1);
            }
        }

        &__title {
            font-size: 1.25rem;
            font-family: "Roboto Condensed", sans-serif;
            line-height: 1.1;
            letter-spacing: 0.025rem;
            margin: 0 0 0.5rem;
            padding: 0 1.5rem;
        }

        &.card--no-image {
            .card__title {
                padding-top: 1.5rem;
                padding-bottom: 1em;
            }
        }

        &__description {
            font-size: 0.9rem;
            color: rgba(0, 0, 0, 0.8);
            font-family: Merriweather, serif;
            padding: 0 1.5rem 1.5rem;
        }

        &:hover {
            .card__thumbnail img {
                transform: scale(1.2);
            }
        }
    }
</style>
