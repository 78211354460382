<template>
    <div class="fallout-password-game">
        <main-menu/>

        <div class="title">Fallout 76 password game</div>

        <div class="menu">
            <a href="/fallout-password-cracker">Cracker &gt;</a>
            <button @click="boot">Reset</button>
        </div>

        <div class="wordlists">
            <div class="wordlist">
                <div class="word" v-for="option in options" @click="pickOption(option)" :class="{'word--correct': option === correctAnswer, 'word--picked': picked.includes(option)}">{{option}}</div>
            </div>
            <div class="matches" v-if="picked.length > 0">
                <div>matches: &nbsp;&nbsp;&nbsp;</div>
                <div class="value">{{matches}}</div>
                <div>attempts left: &nbsp;&nbsp;&nbsp;</div>
                <div class="value">{{attemptsRemaining}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    function matchingLetters(a, b) {
        let matches = 0;

        for (let i = 0; i < a.length; i++) {
            matches += a[i] == b[i] ? 1 : 0;
        }

        return matches;
    }

    export default {
        data() {
            return {
                options: [],
                answer: "",
                correctAnswer: "",
                matches: null,
                picked: [],
                attemptsRemaining: 6,
                wordLengh: 6,
            };
        },

        methods: {
            boot() {
                this.wordLengh = 5 + Math.floor(Math.random() * 3);

                this.$axios.get(`/api/words/random?length=${this.wordLengh}`)
                    .then(r => {
                        this.options = r.data.words;

                        const randomIndex = Math.floor(Math.random() * this.options.length);
                        this.answer = this.options[randomIndex];
                        this.attemptsRemaining = this.wordLengh + 1;
                    });
            },

            pickOption(option) {
                this.picked.push(option);
                this.matches = matchingLetters(option, this.answer);
                console.log(this.attemptsRemaining + 0);
                this.attemptsRemaining -= 1;

                if (this.matches === this.wordLengh) {
                    this.correctAnswer = option;
                }

                if (this.attemptsRemaining === 0) {
                    alert("You lost, but you can keep trying");
                }
            },
        },

        created() {
            this.boot();
        },
    };
</script>

<style lang="less" scoped>
    .vue-app__body {
        font-family: consolas, monospace;
        padding: 2rem;
        filter: contrast(0.2) sepia(1) saturate(1) hue-rotate(66deg);

        @import "../../css/fallout.less";
    }
</style>
