<template>
    <div class="vue-page">
        <h1>Setup some Google Fonts locally.</h1>

        <div>
            <label for="google_font_css">Google Fonts embed URL</label>
            <textarea v-model="css" id="google_font_css"></textarea>
        </div>

        <div class="form-actions">
            <button :disabled="working" type="button" @click="submit">Gimme the thing</button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                css: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap",
                working: false,
            };
        },

        methods: {
            submit() {
                const postData = {
                    css: this.css,
                    renameFont: true,
                };

                this.working = true;

                this.$axios
                    .post("/api/google-fonts/index", postData)
                    .then(r => {
                        console.log(r.data);
                        this.working = false;
                    })
                .catch(err => {
                    this.working = false;
                    console.log(err);
                });
            }
        },

        computed: {
        },

        created() {
        },
    };
</script>

<style lang="less" scoped>
    .vue-page {
        min-height: 100vh;
        background-color: #f2f2f2;
        padding: 2rem;
        font-family: sans-serif;
    }

    h1 {
        font-size: 4rem;
        margin: 0 0 0.25em 0;
    }

    label {
        text-transform: uppercase;
        letter-spacing: 0.01em;
        color: #333;
    }

    textarea {
        border: 1px solid orange;
        padding: 0.25em;
        display: block;
        width: 100%;
        font-size: 1.2rem;
        border-radius: 2px;
    }

    .form-actions {
        margin-top: 1rem;

        button {}
    }
</style>
