<template>
    <div :class="`${prefix}--main-menu`">
        <div class="menu__toggle" @click="toggle"><span></span><span></span><span></span></div>
        <transition name="app_menu">
            <div class="menu__list" v-if="isOpen">
                <a v-for="item in menuItems" class="menu__item" :href="item.url">{{item.label}}</a>
            </div>
        </transition>
    </div>
</template>

<script>
    import menuItems from "../../../data/menu.js";

    const items = Object.values(menuItems).filter(it => {
        console.log(it);
        return it.enabled;
    });

    export default {
        props: {
            prefix: {
                type: String,
                default: "app",
            },
            open: {
                type: Boolean,
                default: false,
            }
        },

        data() {
            return {
                isOpen: this.open,
                menuItems: items,
            };
        },

        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
            },
        }
    };
</script>
