export default {
    home: {
        label: "Dottie",
        url: "/",
        enabled: true,
    },

    base64: {
        label: "Base64",
        url: "/base64-encoder",
        enabled: true,
    },

    colorPairs: {
        label: "Color Pairs",
        url: "/color-pairs",
        enabled: true,
    },

    fallout: {
        label: "Fallout 76 Password Game",
        url: "/fallout-password-game",
        enabled: false,
    },

    wishlist: {
        label: "PlayStation Wishlist",
        url: "/wishlist",
        enabled: false,
    },

    randomSequences: {
        label: "Random Sequences",
        url: "/random-sequences",
        enabled: true,
    },

    remToPx: {
        label: "REM to PX",
        url: "/rem-to-px",
        enabled: true,
    },

    trophyAppeal: {
        label: "Trophy Appeal Calculator",
        url: "/trophy-appeal",
        enabled: false,
    },

    downloadAll: {
        label: "URL Downloader",
        url: "/download-all",
        enabled: false,
    }
};
