var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "clip", rawName: "v-clip", value: _vm.css, expression: "css" },
      ],
      staticClass: "color-pair",
      style: _vm.styles,
      attrs: { title: "Click to copy to clipboard" },
    },
    [
      _c("div", { staticClass: "color-pair__color color-pair__color--fg" }, [
        _vm._v(_vm._s(_vm.fg)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "color-pair__color color-pair__color--bg" }, [
        _vm._v(_vm._s(_vm.bg)),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "color-pair__button",
          style: { backgroundColor: this.fg, color: this.bg },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.flip.apply(null, arguments)
            },
          },
        },
        [_c("flip-icon")],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "color-pair__save",
          style: { color: this.fg },
          attrs: {
            title: "Click to toggle favorite, press F to see your favorites",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.favorite.apply(null, arguments)
            },
          },
        },
        [
          _c("heart-on-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isFavorite === true,
                expression: "isFavorite === true",
              },
            ],
          }),
          _vm._v(" "),
          _c("heart-off-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isFavorite === false,
                expression: "isFavorite === false",
              },
            ],
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }