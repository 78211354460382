var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-page" },
    [
      _c("main-menu", { attrs: { prefix: "trophy-appeal" } }),
      _vm._v(" "),
      _c("div", { staticClass: "page" }, [
        _c("div", { staticClass: "page-title" }, [
          _c("h1", [
            _vm._v("Trophy appeal calculator "),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    _vm.showDetails = !_vm.showDetails
                  },
                },
              },
              [_vm._v("?")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDetails,
                  expression: "showDetails",
                },
              ],
              staticClass: "help",
            },
            [
              _c("p", [_vm._v("The Appeal Index™ is calculated in this way:")]),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "toolbar" }, [
          _c("div", { staticClass: "toolbar-group" }, [
            _c("div", { staticClass: "filter-input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nameFilter,
                    expression: "nameFilter",
                  },
                ],
                attrs: { type: "text", placeholder: "Filter..." },
                domProps: { value: _vm.nameFilter },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.nameFilter = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.nameFilter,
                      expression: "nameFilter",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.nameFilter = ""
                    },
                  },
                },
                [_vm._v("×")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.onlyPlat,
                    expression: "onlyPlat",
                  },
                ],
                attrs: { type: "checkbox", id: "only-plat" },
                domProps: {
                  checked: Array.isArray(_vm.onlyPlat)
                    ? _vm._i(_vm.onlyPlat, null) > -1
                    : _vm.onlyPlat,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.onlyPlat,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.onlyPlat = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.onlyPlat = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.onlyPlat = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "only-plat" } }, [
                _vm._v("Only Plat"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "toolbar-group" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.openGameForm()
                  },
                },
              },
              [_vm._v("Add game")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("table", { attrs: { cellspacing: "0", cellpadding: "0" } }, [
          _c("thead", [
            _c("tr", [
              _c(
                "th",
                {
                  class: [].concat(_vm.sortClass("name")),
                  on: {
                    click: function ($event) {
                      return _vm.updateSort("name")
                    },
                  },
                },
                [_c("span", [_vm._v("Name")])]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  class: [].concat(_vm.sortClass("length")),
                  on: {
                    click: function ($event) {
                      return _vm.updateSort("length")
                    },
                  },
                },
                [_c("span", [_vm._v("Length")])]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  class: [].concat(_vm.sortClass("appeal")),
                  attrs: { width: "20%" },
                  on: {
                    click: function ($event) {
                      return _vm.updateSort("appeal")
                    },
                  },
                },
                [_c("span", [_vm._v("Appeal")])]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  class: [_vm.sortClass("index")],
                  attrs: { width: "12%" },
                  on: {
                    click: function ($event) {
                      return _vm.updateSort("index")
                    },
                  },
                },
                [_c("span", [_vm._v("Index")])]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.display, function (g, index) {
              return _c("tr", { key: g.uuid, staticClass: "game" }, [
                _c("td", { attrs: { colspan: "2" } }, [
                  _c("div", { staticClass: "game-index" }, [
                    _vm._v(_vm._s((index + 1).toString(10).padStart(3, "0"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "game-name" },
                    [
                      _c("span", [_vm._v(_vm._s(g.name))]),
                      _vm._v(" "),
                      _c("game-dropdown", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openGameForm("edit", g)
                              },
                            },
                          },
                          [_vm._v("Edit")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#`" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteGame(g)
                              },
                            },
                          },
                          [_vm._v("Delete")]
                        ),
                        _vm._v(" "),
                        _c("a", { attrs: { href: _vm.searchLink(g) } }, [
                          _vm._v("Trophy Guide"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "game-info" }, [
                    _c(
                      "span",
                      {
                        staticClass: "has-platinum",
                        class: ["has-platinum--" + g.hasPlatinum],
                      },
                      [_vm._v(" Platinum")]
                    ),
                    _vm._v(
                      "\n                            —\n                            "
                    ),
                    _c("span", { staticClass: "time-to-platinum" }, [
                      _vm._v(_vm._s(g.timeToPlatinum) + " hours"),
                    ]),
                    _vm._v(
                      ",\n                            " +
                        _vm._s(g.platinumDifficulty)
                    ),
                    _c("span", { staticClass: "platinum-difficulty--out-of" }, [
                      _vm._v("/10"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s("♥".repeat(g.appeal))),
                  _c("span", { staticClass: "appeal--out-of" }, [
                    _vm._v(_vm._s("♥".repeat(5 - g.appeal))),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", { staticClass: "appeal-index" }, [
                    _vm._v(_vm._s(g.index)),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.showGameForm
        ? _c("div", { staticClass: "screen" }, [
            _c("div", { staticClass: "modal" }, [
              _c(
                "form",
                {
                  attrs: { action: "/", method: "post" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "gameForm__name" } }, [
                      _vm._v("Name"),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.gameForm.name,
                            expression: "gameForm.name",
                          },
                        ],
                        attrs: { id: "gameForm__name", type: "text" },
                        domProps: { value: _vm.gameForm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.gameForm, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gameForm.hasPlatinum,
                          expression: "gameForm.hasPlatinum",
                        },
                      ],
                      attrs: { type: "checkbox", id: "gameForm_hasPlatinum" },
                      domProps: {
                        checked: Array.isArray(_vm.gameForm.hasPlatinum)
                          ? _vm._i(_vm.gameForm.hasPlatinum, null) > -1
                          : _vm.gameForm.hasPlatinum,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.gameForm.hasPlatinum,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.gameForm,
                                  "hasPlatinum",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.gameForm,
                                  "hasPlatinum",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.gameForm, "hasPlatinum", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "gameForm_hasPlatinum" } }, [
                      _vm._v("Has Platinum Trophy"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "gameForm_appeal" } }, [
                      _vm._v("Appeal (1 to 5)"),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.gameForm.appeal,
                            expression: "gameForm.appeal",
                          },
                        ],
                        attrs: { id: "gameForm_appeal", type: "text" },
                        domProps: { value: _vm.gameForm.appeal },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.gameForm,
                              "appeal",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: "gameForm_platinumDifficulty" } },
                      [_vm._v("Platinum difficulty (1 to 10)")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.gameForm.platinumDifficulty,
                            expression: "gameForm.platinumDifficulty",
                          },
                        ],
                        attrs: {
                          id: "gameForm_platinumDifficulty",
                          type: "text",
                        },
                        domProps: { value: _vm.gameForm.platinumDifficulty },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.gameForm,
                              "platinumDifficulty",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "gameForm_timeToPlatinum" } }, [
                      _vm._v("Estimated time to 100% (in hours)"),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.gameForm.timeToPlatinum,
                            expression: "gameForm.timeToPlatinum",
                          },
                        ],
                        attrs: { id: "gameForm_timeToPlatinum", type: "text" },
                        domProps: { value: _vm.gameForm.timeToPlatinum },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.gameForm,
                              "timeToPlatinum",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group form-actions" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeGameForm.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c("button", { attrs: { type: "submit" } }, [
                      _vm._v("Add game"),
                    ]),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [
        _vm._v("\n                        A first factor ("),
        _c("code", [_vm._v("p")]),
        _vm._v(
          ") is calculated by adding together 2 values:\n                        "
        ),
        _c("ul", [
          _c("li", [_vm._v("2 points for each heart")]),
          _vm._v(" "),
          _c("li", [_vm._v("3 points if the game has a Platinum Trophy")]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Factor "),
        _c("code", [_vm._v("p")]),
        _vm._v(
          " will be a number between 2 (1 heart, no platinum) to 13 (5 hearts and platinum trophy)"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("\n                        A second factor ("),
        _c("code", [_vm._v("n")]),
        _vm._v(
          ") is calculated by adding together two values:\n                        "
        ),
        _c("ul", [
          _c("li", [
            _vm._v("Difficulty, using this formula: "),
            _c("code", [_vm._v("ln(max(1, difficulty - 3) * 5)")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("Time to complete, using this formula: "),
            _c("code", [_vm._v("ln(time)")]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("The final index is computed by dividing both factors:"),
      ]),
      _vm._v(" "),
      _c("li", [_c("code", [_vm._v("(p / n) * 100")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }