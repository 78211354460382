var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "dottie", staticClass: "dottie-app" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { id: "vue-menu--dottie" } },
      [_c("main-menu", { attrs: { open: true } })],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "matrix" }, [
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-0" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-20" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-20" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-0" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-20" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-40" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-11" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-40" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-20" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-40" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-40" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-21" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-80" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-90" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-90" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-80" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-90" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-100" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-100" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-90" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-90" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-100" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-100" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-90" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-12" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-80" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-90" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-90" }, [
          _c("div", { staticClass: "animated-2 dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-80" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-40" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-70" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-40" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-20" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-40" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-50" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-40" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-20" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-row" }, [
        _c("div", { staticClass: "matrix-cell opacity-0" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-20" }, [
          _c("div", { staticClass: "dottie-dot-1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot--2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-30" }, [
          _c("div", { staticClass: "dottie-dot-11" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-20" }, [
          _c("div", { staticClass: "dottie-dot-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-cell opacity-0" }, [
          _c("div", { staticClass: "dottie-dot--1" }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "none" }, attrs: { id: "help" } },
      [
        _c("div", [
          _vm._v("press "),
          _c("code", [_vm._v("h")]),
          _vm._v(" to show or hide this help panel"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("press "),
          _c("code", [_vm._v("c")]),
          _vm._v(" to clear the matrix"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("press "),
          _c("code", [_vm._v("r")]),
          _vm._v(" to display a random matrix"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("press "),
          _c("code", [_vm._v("p")]),
          _vm._v(" to print the current matrix to the console"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("press "),
          _c("code", [_vm._v("s")]),
          _vm._v(" to save the current matrix"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("press "),
          _c("code", [_vm._v("l")]),
          _vm._v(" to load the saved matrix"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("press "),
          _c("code", [_vm._v("x")]),
          _vm._v(" to randomize the grid"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }