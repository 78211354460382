var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fallout-password-game" },
    [
      _c("main-menu"),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("Fallout 76 password game")]),
      _vm._v(" "),
      _c("div", { staticClass: "menu" }, [
        _c("a", { attrs: { href: "/fallout-password-cracker" } }, [
          _vm._v("Cracker >"),
        ]),
        _vm._v(" "),
        _c("button", { on: { click: _vm.boot } }, [_vm._v("Reset")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wordlists" }, [
        _c(
          "div",
          { staticClass: "wordlist" },
          _vm._l(_vm.options, function (option) {
            return _c(
              "div",
              {
                staticClass: "word",
                class: {
                  "word--correct": option === _vm.correctAnswer,
                  "word--picked": _vm.picked.includes(option),
                },
                on: {
                  click: function ($event) {
                    return _vm.pickOption(option)
                  },
                },
              },
              [_vm._v(_vm._s(option))]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.picked.length > 0
          ? _c("div", { staticClass: "matches" }, [
              _c("div", [_vm._v("matches:    ")]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.matches)),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("attempts left:    ")]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.attemptsRemaining)),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }