var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-app" }, [
    _c("div", { staticClass: "ui-container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "ui-panel max-w-40 mar-t-3 mar-b-3" }, [
        _c("div", { staticClass: "ui-filter" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filter,
                expression: "filter",
              },
            ],
            staticClass: "ui-filter__input",
            attrs: { type: "text" },
            domProps: { value: _vm.filter },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.filter = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "ui-filter__clear-button",
              attrs: { disabled: !_vm.filter },
              on: {
                click: function ($event) {
                  _vm.filter = ""
                },
              },
            },
            [_vm._v("×")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ui-list" },
          _vm._l(_vm.results, function (g) {
            return _c("div", { key: g.id, staticClass: "ui-block" }, [
              _c("div", [
                _c("img", {
                  staticClass: "thumbnail",
                  attrs: {
                    src: "/wishlist-thumbnails/img_" + g.id + ".png",
                    alt: "",
                  },
                }),
                _vm._v(
                  "\n                        " +
                    _vm._s(g.name) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(g.price.toLocaleString("es-ES")) + " €"),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-page-title" }, [
      _c("h1", { staticClass: "ui-page-title__title" }, [_vm._v("Wishlist")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }