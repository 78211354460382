<template>
    <div class="app">
        <div id="vue-menu--base64" class="menu">
            <main-menu :open="true" />
<!--
            <a v-for="item in menuItems" :href="item.url">{{item.label}}</a>
-->
        </div>

        <h1 class="app__title">Base 64 encoder</h1>

        <div class="app__body">
            <button class="app__flip-button" @click="flip"><RefreshIcon class="icon-16" /></button>

            <div class="encoder__group encoder__group--encoded" :style="{order: source === 'encoded' ? -1 : 0}">
                <input class="encoder__input" type="text" name="encoded" :disabled="source !== 'encoded'" v-model="encoded" placeholder="Base 64 text here...">
            </div>

            <div class="encoder__group encoder__group--decoded" :style="{order: source === 'decoded' ? -1 : 0}">
                <input class="encoder__input" type="text" name="decoded" :disabled="source !== 'decoded'" v-model="decoded" placeholder="Plain text here...">
            </div>
        </div>
    </div>
</template>

<script>
    import menuItems from "../../../data/menu";
    import RefreshIcon from "@fortawesome/fontawesome-free/svgs/solid/redo.svg?inline";
    import MainMenu from "@/js/components/MainMenu";

    export default {
        components: {RefreshIcon, MainMenu},

        data() {
            return {
                encoded: "",
                decoded: null,
                source: "encoded",
                menuItems,
            };
        },

        methods: {
            flip() {
                const opposite = {
                    encoded: "decoded",
                    decoded: "encoded",
                };

                this.source = opposite[this.source];
            }
        },

        created() {
            this.decoded = atob(this.encoded);
        },

        watch: {
            encoded(next, prev) {
                if (this.source === "encoded") {
                    this.decoded = atob(this.encoded);
                }
            },

            decoded(next, prev) {
                if (this.source === "decoded") {
                    this.encoded = btoa(this.decoded);
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .vue-app__body {
        margin: 0;
        padding: 4rem;
        min-height: 100vh;
        background: white linear-gradient(70deg, #afdcd7, #a7dcca, #a7dbb9, #b0d8a4, #bfd38f, #d2cb7c, #e8c16f, #ffb56b) no-repeat center;
        font: 1em sans-serif;
        justify-content: center;
        align-items: center;
    }

    .app {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: relative;

        &__title {
            font-size: 4rem;
            margin: 0 auto 2rem;
            text-align: center;
            text-transform: uppercase;
            opacity: 0.25;
        }

        &__body {
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 0.25rem;
            padding: 4rem;
            margin-bottom: 8rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            position: relative;
            box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
        }

        &__flip-button {
            position: absolute;
            top: -1rem;
            right: -1rem;
            border-radius: 999px;
            border: 0 none;
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            padding: 0;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            background-color: #def;
            color: deepskyblue;
            transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

            &:hover {
                background-color: #fff;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .encoder {
        &__group {}

        &__input {
            font-family: sans-serif;
            font-size: 1.4rem;
            padding: 0.5rem;
            min-width: 50rem;
            display: block;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
    }

    .icon-16 {
        width: 16px;
        height: 16px;
        fill: deepskyblue;
    }
</style>

<style lang="less">
    #vue-menu--base64 {
        .menu__list {
            position: fixed;
            top: 3rem;
            left: 3rem;
            display: flex;
            flex-direction: column;

            a {
                display: inline-block;
                padding: 0.5rem 1rem;
                text-decoration: none;
                color: rgba(0, 0, 0, 0.55);
                font-weight: bold;
                transition: color 1s cubic-bezier(0, 1, 0.5, 1);
                border-radius: 4px;

                &:hover {
                    color: rgba(0, 0, 0, 0.95);
                    background: transparent linear-gradient(45deg, rgb(128 0 128 / 0.25), rgb(255 190 80 / 0.25));
                    background-attachment: fixed;
                    background-size: 20vw 25vh;
                }
            }
        }

    }
</style>
