<template>
    <div>
        <div class="color-pair-grid">
            <color-pair v-for="c in colorPairs" :key="c.join()" :colors="c" :gradient="showGradients" />
        </div>

        <div class="options" v-show="showOptions">
            <label class="option">
                <input type="checkbox" v-model="showGradients"> Show gradients
            </label>
        </div>
    </div>
</template>

<script>
    import chroma from "chroma-js";
    import ColorPair from "../components/ColorPair.vue";
    import {getLocal, setLocal} from "../local.js";

    const PAIR_COUNT = 25;

    export default {
        components: {ColorPair},

        data() {
            return {
                colorPairs: [],
                showOptions: false,
                showGradients: false,
            };
        },

        methods: {
            createPairs() {
                this.colorPairs = [];

                for (let i = 0; i < PAIR_COUNT; i++) {
                    this.colorPairs.push([
                        chroma.random().hex(),
                        chroma.random().hex(),
                    ]);
                }
            },

            showFavorites() {
                const favorites = getLocal("home.colorPairs.favorites", []);
                this.colorPairs = favorites.map((cp) => [cp.fg, cp.bg]);
            },
        },

        created() {
            this.createPairs();

            document.body.addEventListener("keyup", $event => {
                if ($event.key === "o") {
                    this.showOptions = ! this.showOptions;
                }

                if ($event.key === "g") {
                    this.showGradients = ! this.showGradients;
                }
            });

            window.document.body.addEventListener("keyup", (event) => {
                if (event.code === "KeyF") {
                    console.log('this.showFavorites();');
                    this.showFavorites();
                }

                if (event.code === "KeyR") {
                    console.log('this.refreshColors();');
                    this.createPairs();
                }
            });
        },
    };
</script>

<style lang="less" scoped>
    .color-pair-grid {
        display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        // grid-auto-rows: 280px;
        grid-template-columns: repeat(5, 20vw);
        grid-auto-rows: 20vh;
    }

    .options {
        position: fixed;
        top: 0;
        left: 0;
        padding: 1rem;
        font-family: Cabin, "Comic Sans MS", monospace;
        background-color: #fff;
        color: #000;
    }
</style>
