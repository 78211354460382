var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fallout-password-cracker" },
    [
      _c("main-menu"),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("Fallout 76 computer code cracker"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "menu" }, [
        _c("a", { attrs: { href: "/fallout-password-game" } }, [
          _vm._v("Play the game >"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button--ask-for-words",
            on: {
              click: function ($event) {
                _vm.askForWords = true
              },
            },
          },
          [_vm._v("Choose words")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wordlists" },
        [
          _c(
            "div",
            { staticClass: "wordlist wordlist--input" },
            [
              _c("input", {
                staticClass: "input-matches",
                staticStyle: { visibility: "hidden" },
                attrs: { type: "text" },
              }),
              _vm._v(" "),
              _vm._l(_vm.input, function (word) {
                return _c(
                  "div",
                  {
                    staticClass: "word",
                    class: { "word--picked": _vm.isPicked(word, 0) },
                    on: {
                      click: function ($event) {
                        return _vm.pick(word, 0)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " + _vm._s(word) + "\n            "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._l(_vm.solutions, function (words, listIndex) {
            return _c(
              "div",
              { staticClass: "wordlist" },
              [
                _c("label", {}, [_vm._v("Matches")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.picks[listIndex].matches,
                      expression: "picks[listIndex].matches",
                    },
                  ],
                  staticClass: "input-matches",
                  class: ["input-matches--" + listIndex],
                  attrs: { type: "text" },
                  domProps: { value: _vm.picks[listIndex].matches },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.picks[listIndex],
                        "matches",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm._l(words, function (word) {
                  return _c(
                    "div",
                    {
                      staticClass: "word",
                      class: {
                        picked: _vm.isPicked(word, listIndex + 1),
                        inactive: _vm.isInactive(word),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.pick(word, listIndex + 1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(word) + "\n            ")]
                  )
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.askForWords,
              expression: "askForWords",
            },
          ],
          staticClass: "enter-popup",
        },
        [
          _c("div", { staticClass: "enter-popup__form" }, [
            _c("div", [_vm._v("Enter 20 words, 1 per line.")]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newWordList,
                  expression: "newWordList",
                },
              ],
              staticClass: "enter-popup__textarea",
              domProps: { value: _vm.newWordList },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newWordList = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "enter-popup__actions" }, [
              _c("button", { on: { click: _vm.cancelAskForWords } }, [
                _vm._v("Cancel"),
              ]),
              _vm._v(" "),
              _c("button", { on: { click: _vm.updateWordList } }, [
                _vm._v("Continue"),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }