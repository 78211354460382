<template>
    <div class="fallout-password-cracker">
        <main-menu/>

        <div class="title">Fallout 76 computer code cracker</div>

        <div class="menu">
            <a href="/fallout-password-game">Play the game &gt;</a>
            <button class="button--ask-for-words" @click="askForWords = true">Choose words</button>
        </div>

        <div class="wordlists">
            <div class="wordlist wordlist--input">
                <input class="input-matches" type="text" style="visibility: hidden">
                <div class="word" v-for="word in input" @click="pick(word, 0)" :class="{'word--picked': isPicked(word, 0)}">
                    {{word}}
                </div>
            </div>

            <div class="wordlist" v-for="(words, listIndex) in solutions">
                <label style="">Matches</label>
                <input class="input-matches" :class="[`input-matches--${listIndex}`]" type="text"
                       v-model="picks[listIndex].matches">
                <div class="word" v-for="word in words"
                     :class="{'picked': isPicked(word, listIndex + 1), 'inactive': isInactive(word)}"
                     @click="pick(word, listIndex + 1)">{{word}}
                </div>
            </div>
        </div>

        <div class="enter-popup" v-show="askForWords">
            <div class="enter-popup__form">
                <div>Enter 20 words, 1 per line.</div>

                <textarea class="enter-popup__textarea" v-model="newWordList"></textarea>

                <div class="enter-popup__actions">
                    <button @click="cancelAskForWords">Cancel</button>
                    <button @click="updateWordList">Continue</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const dump = (...v) => console.log(JSON.parse(JSON.stringify([...v])));

    const DISABLED_WORD = "-----";

    function lettersInCommon(a, b) {
        const aLetters = a.split("");
        const bLetters = b.split("");

        let matches = 0;

        for (let letter of aLetters) {
            if (bLetters.includes(letter)) {
                matches += 1;
            }
        }

        return matches;
    }

    function matchingLetters(a, b) {
        let matches = 0;

        for (let i = 0; i < a.length; i++) {
            matches += a[i] == b[i] ? 1 : 0;
        }

        return matches;
    }

    export default {
        data() {
            return {
                input: [
                    "aorist",
                    "seaman",
                    "refuse",
                    "tramps",
                    "annuls",
                    "cattle",
                    "dwells",
                    "relent",
                    "seeing",
                    "acidly",
                    "fatten",
                    "sanded",
                    "spicer",
                    "galaxy",
                    "repent",
                    "thrash",
                    "busied",
                    "daniel",
                    "player",
                    "sheets",
                ],
                picks: [],
                askForWords: false,
                newWordList: "",
            };
        },

        methods: {
            pick(word, listIndex) {
                if (word === DISABLED_WORD) {
                    return;
                }

                const newPicks = this.picks.slice(0, listIndex);
                newPicks[listIndex] = {word, matches: (newPicks[listIndex] ? newPicks[listIndex].matches : 0)};

                this.picks = newPicks;
            },

            isPicked(word, listIndex) {
                if (this.picks[listIndex]) {
                    return this.picks[listIndex].word === word;
                }

                return false;
            },

            isInactive(word) {
                return word === DISABLED_WORD;
            },

            updateWordList() {
                this.input = this.newWordList.split("\n");
                this.newWordList = "";
                this.askForWords = false;
            },

            cancelAskForWords() {
                this.askForWords = false;
                this.newWordList = "";
            }
        },

        computed: {
            solutions() {
                const wordlists = [];
                let base = this.input.slice();

                this.picks.forEach((pick, listIndex) => {
                    const discard = this.picks.slice(0, listIndex).map(p => p.word);

                    const list = base.map(item => {
                        if (discard.includes(item)) {
                            return DISABLED_WORD;
                        }

                        const matches = matchingLetters(item, pick.word);
                        const mustBe = parseInt(pick.matches);

                        if (matches !== mustBe) {
                            return DISABLED_WORD;
                        }

                        return item;
                    });

                    wordlists.push(list);
                    base = list;
                });

                return wordlists;
            },
        },
    };
</script>

<style lang="less" scoped>
    .vue-app__body {
        font-family: consolas, monospace;
        padding: 2rem;
        filter: contrast(0.2) sepia(1) saturate(1) hue-rotate(66deg);

        @import "../../css/fallout.less";
    }
</style>
