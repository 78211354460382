var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-page gradients-page" }, [
    _c("div", { staticClass: "card", style: _vm.boxStyles }),
    _vm._v(" "),
    _c("div", { staticClass: "form" }, [
      _c("pre", [
        _c("code", [
          _vm._v(
            ".card {\n    background-image: " +
              _vm._s(_vm.boxStyles.backgroundImage) +
              "\n}"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "steps", style: _vm.fancyGradient }),
      _vm._v(" "),
      _c("h4", [_vm._v("Linear gradient")]),
      _vm._v(" "),
      _c("div", { staticClass: "color-input" }, [
        _c("label", { attrs: { for: "linearGradient_fromColor" } }, [
          _vm._v("From color:"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.linearGradient.fromColor,
              expression: "linearGradient.fromColor",
            },
          ],
          attrs: { type: "text", id: "linearGradient_fromColor" },
          domProps: { value: _vm.linearGradient.fromColor },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.linearGradient, "fromColor", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "swatch",
          style: { backgroundColor: _vm.linearGradient.fromColor },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "color-input" }, [
        _c("label", { attrs: { for: "linearGradient_toColor" } }, [
          _vm._v("To color:"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.linearGradient.toColor,
              expression: "linearGradient.toColor",
            },
          ],
          attrs: { type: "text", id: "linearGradient_toColor" },
          domProps: { value: _vm.linearGradient.toColor },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.linearGradient, "toColor", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "swatch",
          style: { backgroundColor: _vm.linearGradient.toColor },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "angle-input" }, [
        _c("label", { attrs: { for: "linearGradient_angle" } }, [
          _vm._v("Angle:"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.linearGradient.angle,
              expression: "linearGradient.angle",
            },
          ],
          attrs: { type: "text", id: "linearGradient_angle" },
          domProps: { value: _vm.linearGradient.angle },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.linearGradient, "angle", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Radial gradient")]),
      _vm._v(" "),
      _c("div", { staticClass: "color-input" }, [
        _c("label", { attrs: { for: "radialGradient_fromColor" } }, [
          _vm._v("From color:"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.radialGradient.fromColor,
              expression: "radialGradient.fromColor",
            },
          ],
          attrs: { type: "text", id: "radialGradient_fromColor" },
          domProps: { value: _vm.radialGradient.fromColor },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.radialGradient, "fromColor", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "swatch",
          style: { backgroundColor: _vm.radialGradient.fromColor },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "color-input" }, [
        _c("label", { attrs: { for: "radialGradient_toColor" } }, [
          _vm._v("To color:"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.radialGradient.toColor,
              expression: "radialGradient.toColor",
            },
          ],
          attrs: { type: "text", id: "radialGradient_toColor" },
          domProps: { value: _vm.radialGradient.toColor },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.radialGradient, "toColor", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "swatch",
          style: { backgroundColor: _vm.radialGradient.toColor },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "position-input" }, [
        _c("label", { attrs: { for: "radialGradient_position" } }, [
          _vm._v("Angle:"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.radialGradient.position,
              expression: "radialGradient.position",
            },
          ],
          attrs: { type: "text", id: "radialGradient_position" },
          domProps: { value: _vm.radialGradient.position },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.radialGradient, "position", $event.target.value)
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }