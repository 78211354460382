<template>
    <div class="dottie-app" ref="dottie">
        <div class="matrix">
            <div class="matrix-row">
                <div class="matrix-cell opacity-0"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-20"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-20"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-0"><div class="dottie-dot--2"></div></div>
            </div>
            <div class="matrix-row">
                <div class="matrix-cell opacity-20"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-40"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-11"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-40"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-20"><div class="dottie-dot-1"></div></div>
            </div>
            <div class="matrix-row">
                <div class="matrix-cell opacity-30"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-40"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-40"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot-2"></div></div>
            </div>
            <div class="matrix-row">
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-21"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-80"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-90"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-90"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-80"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
            </div>
            <div class="matrix-row">
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-90"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-100"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-100"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-90"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
            </div>
            <div class="matrix-row">
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-90"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-100"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-100"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-90"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
            </div>
            <div class="matrix-row">
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-12"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-80"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-90"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-90"><div class="animated-2 dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-80"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
            </div>
            <div class="matrix-row">
                <div class="matrix-cell opacity-30"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-40"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-70"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-40"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot-1"></div></div>
            </div>
            <div class="matrix-row">
                <div class="matrix-cell opacity-20"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-40"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-50"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-40"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-20"><div class="dottie-dot-2"></div></div>
            </div>
            <div class="matrix-row">
                <div class="matrix-cell opacity-0"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-20"><div class="dottie-dot-1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--1"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot--2"></div></div>
                <div class="matrix-cell opacity-30"><div class="dottie-dot-11"></div></div>
                <div class="matrix-cell opacity-20"><div class="dottie-dot-2"></div></div>
                <div class="matrix-cell opacity-0"><div class="dottie-dot--1"></div></div>
            </div>
        </div>

        <div id="help" style="display: none">
            <div>press <code>h</code> to show or hide this help panel</div>
            <div>press <code>c</code> to clear the matrix</div>
            <div>press <code>r</code> to display a random matrix</div>
            <div>press <code>p</code> to print the current matrix to the console</div>
            <div>press <code>s</code> to save the current matrix</div>
            <div>press <code>l</code> to load the saved matrix</div>
            <div>press <code>x</code> to randomize the grid</div>
        </div>

        <div id="vue-menu--dottie">
            <main-menu :open="true" />
<!--
            <ul>
                <li v-for="item in menuItems" :key="item.url"><a :href="item.url">{{item.label}}</a></li>
            </ul>
-->
        </div>
    </div>
</template>

<script>
    import menuItems from "../../../data/menu";
    import MainMenu from "@/js/components/MainMenu";

    import $ from "jquery";

    console.warn("@todo: Refactor Dottie (./assets/js/pages/DottiePage.vue)");

    $(function () {
        var $matrix = $('.matrix');
        var emptyMatrix = [
            [-1, -2, -1, -2, -1, -2, -1, -2, -1, -2],
            [-2, -1, -2, -1, -2, -1, -2, -1, -2, -1],
            [-1, -2, -1, -2, -1, -2, -1, -2, -1, -2],
            [-2, -1, -2, -1, -2, -1, -2, -1, -2, -1],
            [-1, -2, -1, -2, -1, -2, -1, -2, -1, -2],
            [-2, -1, -2, -1, -2, -1, -2, -1, -2, -1],
            [-1, -2, -1, -2, -1, -2, -1, -2, -1, -2],
            [-2, -1, -2, -1, -2, -1, -2, -1, -2, -1],
            [-1, -2, -1, -2, -1, -2, -1, -2, -1, -2],
            [-2, -1, -2, -1, -2, -1, -2, -1, -2, -1]
        ];
        var matrices = [
            [
                [-1, -2, -1, -2,  1,  2, -1, -2, -1, -2],
                [-2,  1, -2, -1, -2, -1, -2, -1,  2, -1],
                [-1, -2,  1, -2, -1, -2, -1,  2, -1, -2],
                [-2, -1, -2,  1,  2,  1,  2, -1, -2, -1],
                [ 1, -2, -1,  2,  3,  3,  1, -2, -1,  2],
                [ 2, -1, -2,  1,  3,  3,  2, -1, -2,  1],
                [-1, -2, -1,  2,  1,  2,  1, -2, -1, -2],
                [-2, -1,  2, -1, -2, -1, -2,  1, -2, -1],
                [-1,  2, -1, -2, -1, -2, -1, -2,  1, -2],
                [-2, -1, -2, -1,  2,  1, -2, -1, -2, -1]
            ],
            [
                [-1,  2,  1,  2, -1, -2,  1,  2,  1, -2],
                [ 2,  1,  2, -1, -2, -1, -2,  1,  2,  1],
                [ 1,  2, -1, -2, -1, -2, -1, -2,  1,  2],
                [ 2, -1,  2,  1,  2,  1,  2,  1, -2,  1],
                [-1,  2, -1,  2, -1, -2,  1, -2,  1, -2],
                [-2,  1, -2,  1, -2, -1,  2, -1,  2, -1],
                [ 1, -2,  1,  2,  1,  2,  1,  2, -1,  2],
                [ 2,  1, -2, -1, -2, -1, -2, -1,  2,  1],
                [ 1,  2,  1, -2, -1, -2, -1,  2,  1,  2],
                [-2,  1,  2,  1, -2, -1,  2,  1,  2, -1]
            ],
            [
                [-1,  2,  1, -2, -1, -2, -1,  2,  1, -2],
                [ 2, -1, -2,  1,  2,  1,  2, -1, -2,  1],
                [ 1, -2, -1, -2, -1, -2, -1, -2, -1,  2],
                [-2,  1, -2, -1,  2,  1, -2, -1,  2, -1],
                [-1,  2, -1,  2, -1, -2,  1, -2,  1, -2],
                [-2,  1, -2,  1, -2, -1,  2, -1,  2, -1],
                [-1,  2, -1, -2,  1,  2, -1, -2,  1, -2],
                [ 2, -1, -2, -1, -2, -1, -2, -1, -2,  1],
                [ 1, -2, -1,  2,  1,  2,  1, -2, -1,  2],
                [-2,  1,  2, -1, -2, -1, -2,  1,  2, -1]
            ],
            [
                [-1,  2, -1,  2, -1,  2, -1,  2, -1,  2],
                [ 2, -1,  2, -1,  2, -1,  2, -1,  2, -1],
                [-1,  2, -1,  2, -1,  2, -1,  2, -1,  2],
                [ 2, -1,  2, -1,  2, -1,  2, -1,  2, -1],
                [-1,  2, -1,  2, -1,  2, -1,  2, -1,  2],
                [ 2, -1,  2, -1,  2, -1,  2, -1,  2, -1],
                [-1,  2, -1,  2, -1,  2, -1,  2, -1,  2],
                [ 2, -1,  2, -1,  2, -1,  2, -1,  2, -1],
                [-1,  2, -1,  2, -1,  2, -1,  2, -1,  2],
                [ 2, -1,  2, -1,  2, -1,  2, -1,  2, -1]
            ]
        ];

        function get_random_matrix() {
            var selectedMatrix = Math.floor(matrices.length * Math.random());
            var matrixData = matrices[selectedMatrix];

            return matrixData;
        }

        // returns a multiple of 10 between 0 and 10,
        // depending on the row/column distance to
        // the center of the matrix
        var get_opacity_factory = function (max) {
            // "radius" of the matrix
            var shift = Math.floor(max / 2);
            // little nudge for even-width matrices
            var offset = (max % 2 === 0) ? 0.5 : 0;
            // full radius range
            var shift_2 = offset ? shift - 1 : shift;
            // it's a diagonal calculation, after all
            var max_sigma = Math.sqrt(200);

            return function(c, r) {
                // normalize row and column according to the
                // radius of the matrix
                var col_n = parseInt('' + Math.abs(c - (shift - offset)));
                var row_n = parseInt('' + Math.abs(r - (shift - offset)));
                // get row and column  values from 0 to 10
                var x_mod = (col_n / shift_2 * 10);
                var y_mod = (row_n / shift_2 * 10);
                // and calulate the diagonal distance
                var sigma = Math.sqrt(x_mod * x_mod + y_mod * y_mod);

                // the result is the difference between max opacity (10)
                // and the proportional sigma value
                return 10 * Math.ceil(10 - 10 * sigma / max_sigma);
            };
        };

        var print_matrix = function(returnString) {
            var m = [];
            var $matrix = $('.matrix');

            $matrix.children().each(function () {
                var row = [];

                $(this).children().each(function () {
                    var c = $(this).children().attr('class').replace('dottie-dot-', '').replace(/animated-\d+/, '').trim();
                    while (c > 9) {
                        c -= 10;
                    }
                    row.push(c);
                });

                m.push('[' + row.join(', ') + ']');
            });

            if (returnString) {
                return '[' + m.join(', ') + ']';
            } else {
                console.log('[' + m.join(',\n') + ']');
            }
        };

        var random_int = (function(min, max) {
            var difference = max - min;

            return function() {
                return min + Math.round(difference * Math.random());
            };
        }) (1, 4);

        function display_matrix(matrixData) {
            // animate a maximum of 2 cells
            var animation_available = 1 + random_int();
            var get_opacity = get_opacity_factory(matrixData.length);

            $matrix.html('');

            // rows
            for (var r = 0; r < matrixData.length; r++) {
                var rowData = matrixData[r];
                var $row = $('<div class="matrix-row"></div>');

                // column
                for (var c = 0; c < rowData.length; c++) {
                    var value = rowData[c];
                    var $cell = $('<div class="matrix-cell"></div>');
                    var $dot = $('<div></div>');
                    var opacity = get_opacity(r, c);

                    if (value > 0) {
                        if (Math.random() > 0.95 && animation_available) {
                            $dot.addClass('animated-' + random_int());
                            animation_available--;
                        }

                        var clr = Math.random()

                        if (clr > 0.99) {
                            value += 30;
                        } else if (clr > 0.97) {
                            value += 20;
                        } else if (clr > 0.95) {
                            value += 10;
                        }
                    }

                    $dot.addClass('dottie-dot-' + value);
                    $cell.addClass('opacity-' + opacity);

                    $cell.append($dot);
                    $row.append($cell);
                }

                $matrix.append($row);
            }
        }

        function save_matrix() {
            var jsonMatrix = print_matrix(true);
            window.localStorage.setItem('matrix', jsonMatrix);
        }

        function load_matrix() {
            var jsonMatrix = window.localStorage.getItem('matrix');

            if (jsonMatrix) {
                var matrix = JSON.parse(jsonMatrix);
                display_matrix(matrix);
            }
        }

        function create_random() {
            var values = [-2, -1, 1, 2, 3];
            var matrix = [];

            for (var r = 0; r < 8; r++) {
                var row = [];

                for (var c = 0; c < 8; c++) {
                    row.push(values[Math.floor(5 * Math.random())]);
                }

                matrix.push(row);
            }

            display_matrix(matrix);
        }

        function shockwave() {
            console.log('hahaha, no.');
        }

        $('body').on('click', '.matrix-cell', function () {
            var $segment = $(this).children();

            $segment.removeClass('animated-1');
            $segment.removeClass('animated-2');

            if ($segment.hasClass('dottie-dot--1')) {
                $segment.removeClass('dottie-dot--1');
                $segment.addClass('dottie-dot-1');
            } else if ($segment.hasClass('dottie-dot--2')) {
                $segment.removeClass('dottie-dot--2');
                $segment.addClass('dottie-dot-2');
            } else if ($segment.hasClass('dottie-dot-1')) {
                $segment.removeClass('dottie-dot-1');
                $segment.addClass('dottie-dot--1');
            } else if ($segment.hasClass('dottie-dot-2')) {
                $segment.removeClass('dottie-dot-2');
                $segment.addClass('dottie-dot--2');
            }
        });

        // check for keyboard input
        $('body').on('keyup', function (e) {
            var key = String.fromCharCode(e.which);

            switch (key) {
                case 'C':
                    display_matrix(emptyMatrix);
                    break;
                case 'R':
                    display_matrix(get_random_matrix());
                    break;
                case 'H':
                    $('#help').toggle();
                    break;
                case 'P':
                    print_matrix();
                    break;
                case 'S':
                    save_matrix();
                    break;
                case 'L':
                    load_matrix();
                    break;
                case 'W':
                    shockwave();
                    break;
                case 'X':
                    create_random();
                    break;
            }
        });

        // initialize the grid with a random matrix
        display_matrix(get_random_matrix());
    });
    console.log({MainMenu});

    export default {
        components: { MainMenu },

        data() {
            return{
                menuItems,
            };
        },

        mounted() {
            console.log(this.$refs.dottie);
        }
    };
</script>

<style lang="less">
    .dottie-app {
      font-family: arial, sans-serif;
      background-color: #212121;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    #help {
      position: absolute;
      color: #38c;
      top: 0;
      right: 0;
      padding: 20px;
      text-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
    }
    .links {
      position: fixed;
      display: flex;
      flex-direction: column;
      bottom: 12px;
      right: 12px;
      text-align: right;
      opacity: 0.25;
      transition: opacity 1s ease-in-out;
    }
    .links:hover {
      opacity: 0.9;
    }
    .links a {
      color: #3da;
      font-family: monospace;
      text-decoration: none;
    }
    .links a:hover {
      color: #38c;
    }
    .matrix {
      display: flex;
      flex-direction: column;
    }
    .matrix-row {
      height: 40px;
      width: 480px;
      display: flex;
      flex-direction: row;
    }
    .matrix-cell {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .dottie-dot-1 {
      width: 40px;
      height: 5px;
      background-color: #38c;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(45deg) scale(1.3);
    }
    .dottie-dot-2 {
      width: 40px;
      height: 5px;
      background-color: #38c;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(-45deg) scale(1.3);
    }
    .dottie-dot-3 {
      width: 40px;
      height: 5px;
      background-color: #38c;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      width: 5px;
    }
    .dottie-dot-11 {
      width: 40px;
      height: 5px;
      background-color: #3bc;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #33bbcc, 0 0 5px rgba(51, 187, 204, 0.75), 0 0 10px rgba(51, 187, 204, 0.5), 0 0 15px rgba(51, 187, 204, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(45deg) scale(1.3);
    }
    .dottie-dot-12 {
      width: 40px;
      height: 5px;
      background-color: #3bc;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #33bbcc, 0 0 5px rgba(51, 187, 204, 0.75), 0 0 10px rgba(51, 187, 204, 0.5), 0 0 15px rgba(51, 187, 204, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(-45deg) scale(1.3);
    }
    .dottie-dot-13 {
      width: 40px;
      height: 5px;
      background-color: #3bc;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #33bbcc, 0 0 5px rgba(51, 187, 204, 0.75), 0 0 10px rgba(51, 187, 204, 0.5), 0 0 15px rgba(51, 187, 204, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      width: 5px;
    }
    .dottie-dot-21 {
      width: 40px;
      height: 5px;
      background-color: #3da;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #33ddaa, 0 0 5px rgba(51, 221, 170, 0.75), 0 0 10px rgba(51, 221, 170, 0.5), 0 0 15px rgba(51, 221, 170, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(45deg) scale(1.3);
    }
    .dottie-dot-22 {
      width: 40px;
      height: 5px;
      background-color: #3da;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #33ddaa, 0 0 5px rgba(51, 221, 170, 0.75), 0 0 10px rgba(51, 221, 170, 0.5), 0 0 15px rgba(51, 221, 170, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(-45deg) scale(1.3);
    }
    .dottie-dot-23 {
      width: 40px;
      height: 5px;
      background-color: #3da;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #33ddaa, 0 0 5px rgba(51, 221, 170, 0.75), 0 0 10px rgba(51, 221, 170, 0.5), 0 0 15px rgba(51, 221, 170, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      width: 5px;
    }
    .dottie-dot-31 {
      width: 40px;
      height: 5px;
      background-color: #E42;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #ee4422, 0 0 5px rgba(238, 68, 34, 0.75), 0 0 10px rgba(238, 68, 34, 0.5), 0 0 15px rgba(238, 68, 34, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(45deg) scale(1.3);
    }
    .dottie-dot-32 {
      width: 40px;
      height: 5px;
      background-color: #E42;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #ee4422, 0 0 5px rgba(238, 68, 34, 0.75), 0 0 10px rgba(238, 68, 34, 0.5), 0 0 15px rgba(238, 68, 34, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(-45deg) scale(1.3);
    }
    .dottie-dot-33 {
      width: 40px;
      height: 5px;
      background-color: #E42;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #ee4422, 0 0 5px rgba(238, 68, 34, 0.75), 0 0 10px rgba(238, 68, 34, 0.5), 0 0 15px rgba(238, 68, 34, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      width: 5px;
    }
    .dottie-dot--1 {
      width: 40px;
      height: 5px;
      background-color: #292929;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #292929, 0 0 5px rgba(41, 41, 41, 0.75), 0 0 10px rgba(41, 41, 41, 0.5), 0 0 15px rgba(41, 41, 41, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(45deg) scale(1.3);
    }
    .dottie-dot--2 {
      width: 40px;
      height: 5px;
      background-color: #292929;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #292929, 0 0 5px rgba(41, 41, 41, 0.75), 0 0 10px rgba(41, 41, 41, 0.5), 0 0 15px rgba(41, 41, 41, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(-45deg) scale(1.3);
    }
    .dottie-dot-cross {
      width: 40px;
      height: 5px;
      background-color: #38c;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      transform: rotate(45deg) scale(1.3);
    }
    .dottie-dot-cross:after {
      width: 40px;
      height: 5px;
      background-color: #38c;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
      animation: fade-in 0.5s ease-in;
      animation-fill-mode: both;
      content: "";
      position: absolute;
      transform: rotate(90deg);
    }
    .animated-1 {
      animation: blink 1s infinite;
    }
    .animated-2 {
      opacity: 1;
      animation-name: neon-start;
      animation-duration: 5s;
      animation-delay: 0s;
      animation-iteration-count: 1;
    }
    .animated-3 {
      animation-name: slow-fade;
      animation-duration: 15s;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-fill-mode: both;
    }
    .animated-4 {
      animation: defect 8s infinite;
    }
    .opacity-100 {
      opacity: 1;
    }
    .opacity-90 {
      opacity: 0.9;
    }
    .opacity-80 {
      opacity: 0.8;
    }
    .opacity-70 {
      opacity: 0.7;
    }
    .opacity-60 {
      opacity: 0.6;
    }
    .opacity-50 {
      opacity: 0.5;
    }
    .opacity-40 {
      opacity: 0.4;
    }
    .opacity-30 {
      opacity: 0.3;
    }
    .opacity-20 {
      opacity: 0.2;
    }
    .opacity-10 {
      opacity: 0.1;
    }
    .opacity-0 {
      opacity: 0.05;
    }
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes blink {
      0% {
        opacity: 0;
      }
      15% {
        opacity: 1;
      }
      85% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes neon-start {
      0% {
        opacity: 0;
      }
      66.9% {
        opacity: 0;
      }
      67% {
        opacity: 1;
      }
      72% {
        opacity: 1;
      }
      72.1% {
        opacity: 0;
      }
      76.9% {
        opacity: 0;
      }
      77% {
        opacity: 1;
      }
      78.9% {
        opacity: 1;
      }
      79% {
        opacity: 0;
      }
      82% {
        opacity: 0;
      }
      83% {
        opacity: 1;
      }
      86% {
        opacity: 1;
      }
      87% {
        opacity: 0;
      }
      93% {
        opacity: 0;
      }
      94% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes defect {
      0% {
        opacity: 1;
      }
      0.9% {
        opacity: 1;
      }
      1% {
        opacity: 0;
      }
      1.9% {
        opacity: 0;
      }
      2% {
        opacity: 1;
      }
      3% {
        opacity: 1;
      }
      3.1% {
        opacity: 0;
      }
      5% {
        opacity: 0;
      }
      7% {
        opacity: 0;
      }
      7.1% {
        opacity: 1;
      }
      14% {
        opacity: 1;
      }
      34% {
        opacity: 1;
      }
      34.1% {
        opacity: 0;
      }
      65% {
        opacity: 0;
      }
      65.3% {
        opacity: 1;
      }
      65.9% {
        opacity: 0;
      }
      69.0% {
        opacity: 1;
      }
      71% {
        opacity: 1;
      }
      71.1% {
        opacity: 0;
      }
      72% {
        opacity: 0;
      }
      72.1% {
        opacity: 1;
      }
      77% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes slow-fade {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

/*
    #vue-menu {
        position: fixed;
        top: 0;
        left: 0;

        ul {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            justify-content: flex-start;
            align-items: flex-start;

            li {
                display: block;

                a {
                    display: block;
                    font-family: consolas, "liberation mono", menlo, monospace;
                    font-size: 13px;
                    padding: 12px 12px;
                    text-decoration: none;
                    color: #38c;

                    &:hover {
                        background-color: fade(#38c, 15%);
                    }
                }
            }
        }
    }*/
</style>

<style lang="less">
    #vue-menu--dottie{
        .menu {
            &__list {
                position: fixed;
                top: 0;
                left: 0;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;
                justify-content: flex-start;
                align-items: flex-start;
            }

            &__item {
                display: block;
                font-family: consolas, "liberation mono", menlo, monospace;
                font-size: 13px;
                padding: 12px 12px;
                text-decoration: none;
                color: #38c;

                &:hover {
                    background-color: fade(#38c, 15%);
                }
            }
        }
    }
</style>
