<template>
    <div class="vue-page">
        <div class="dropzone"
             @dragover.prevent="dragOver"
             @dragleave.prevent="dragLeave"
             @dragenter.prevent="dragEnter"
             @dragExit.prevent="dragExit"
             @drop.prevent="drop"
        >
            <h1>Hello, this is the Pixel component.</h1>

            <div class="dropzone-notice" v-show="dragging">
                <div>Drop it here</div>
            </div>

            <div class="row">
                <div class="col col--form">
                    <div class="form-group">
                        <label for="block_size">Block size</label>
                        <div class="form-control">
                            <input type="text" id="block_size" v-model="blockSize">
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="target_size">Target size</label>
                        <div class="form-control">
                            <input type="text" id="target_size" v-model="targetSize">
                        </div>
                    </div>
                </div>

                <div class="col" v-show="imageFile">
                    <div class="result">{{result}} [{{resultWidth}} &times; {{resultHeight}}]</div>
                    <canvas id="canvas" ref="canvas"></canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    let lastImage = null;

    export default {
        data() {
            return {
                blockSize: 32,
                targetSize: 50,
                resultWidth: null,
                resultHeight: null,
                imageFile: null,
                dragging: false,
            };
        },

        methods: {
            dragEnter() {
                this.dragging = true;
                console.log("dragEnter");
            },
            dragOver() {console.log("dragOver")},
            dragLeave() {
                // this.dragging = false;
                console.log("dragLeave");
            },
            dragExit() {
                this.dragging = false;
                console.log("dragExit");
            },

            drop($event) {
                this.dragging = false;
                console.log("drop")

                // check if there are dropped files
                if ($event.dataTransfer.files.length > 0) {
                    // get the first file
                    const file = $event.dataTransfer.files[0];
                    const mime = file.type;

                    // check that the MIME type matches the accepted ones
                    if (["image/jpeg", "image/png"].includes(mime)) {
                        const reader = new FileReader();

                        reader.onload = e => {
                            const img = new Image();
                            img.src = e.target.result;

                            img.onload = () => {
                                lastImage = img;
                                // this.pixelate(img);
                                this.imageFile = file.name;
                            };
                        };


                        reader.readAsDataURL(file);
                    }
                }
            },

            pixelate(img) {
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext("2d");

                this.resultWidth = Math.floor(img.width * (this.targetSize / 100));
                this.resultHeight = Math.floor(img.height * (this.targetSize / 100));

                canvas.style.width = `${this.resultWidth}px`;
                canvas.style.height = `${this.resultHeight}px`;

                canvas.width = this.resultWidth;
                canvas.height = this.resultHeight;

                console.log([canvas.width, canvas.height]);

                let blockCount = this.blockSize;

                if (blockCount < 1 || blockCount > 100) {
                    blockCount = Math.max(Math.min(blockCount, 100), 1);
                    this.blockSize = blockCount;
                }

                // get a block size (see demo for this approach)
                const size = blockCount / 100;
                const w = canvas.width * size;
                const h = canvas.height * size;

                // draw the original image at a fraction of the final size
                ctx.drawImage(img, 0, 0, w, h);

                // turn off image aliasing
                ctx.imageSmoothingEnabled = false;

                // enlarge the minimized image to full size
                ctx.drawImage(canvas, 0, 0, w, h, 0, 0, canvas.width, canvas.height);
            }
        },

        computed: {
            result() {
                console.log("computed.result");

                const blockSize = this.blockSize;
                const targetSize = this.targetSize;
                const name = this.imageFile;

                if (lastImage) {
                    this.pixelate(lastImage);
                }

                return `${name} (${targetSize}% @${blockSize})`;
            },
        },

        created() {
        },
    };
</script>

<style lang="less" scoped>
    .vue-page,
    .dropzone {
        min-height: 100vh;
    }

    .dropzone {
        background-color: #eee;
        color: #333;
        padding: 2rem;
        font-family: sans-serif;
    }

    .dropzone-notice {
        position: fixed;
        pointer-events: none;
        background-color: rgba(175, 200, 220, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        > div {
            color: #fff;
            border: 0.1em dashed #fff;
            border-radius: 0.5em;
            padding: 3rem;
            font-size: 4rem;
        }
    }

    canvas {
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
    }

    .row {
        display: flex;

        .col {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .col--form {
            flex: 0 0 12rem;
            margin-right: 2rem;
        }
    }

    .form-group {
        margin-bottom: 1rem;

        label {
            display: block;
            opacity: 0.5;
            font-weight: bold;
            font-size: 0.8rem;
            text-transform: uppercase;
            margin-bottom: 0.15rem;
        }

        input {
            display: block;
            width: 100%;
            font-size: 1.2rem;
            padding: 0.25rem;
            font-family: sans-serif;
            color: rgba(0, 0, 0, 0.9);
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 3px;
        }
    }

    .result {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
</style>
