var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-page" }, [
    _c("div", { staticClass: "vue-page__inner" }, [
      _c("h1", [_vm._v("URL Downloader")]),
      _vm._v(" "),
      _c("section", [
        _c("label", { attrs: { for: "urls" } }, [
          _vm._v("Enter URLs, one per line"),
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.items,
              expression: "items",
            },
          ],
          attrs: { id: "urls" },
          domProps: { value: _vm.items },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.items = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("button", { attrs: { type: "button" }, on: { click: _vm.submit } }, [
          _vm._v("Download all"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }