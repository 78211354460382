<template>
    <div class="color-pair" title="Click to copy to clipboard" v-clip="css" :style="styles">
        <div class="color-pair__color color-pair__color--fg">{{fg}}</div>
        <div class="color-pair__color color-pair__color--bg">{{bg}}</div>
        <button class="color-pair__button" :style="{backgroundColor: this.fg, color: this.bg}" @click.stop="flip"><flip-icon></flip-icon></button>
        <button class="color-pair__save" :style="{color: this.fg}" @click.stop="favorite" title="Click to toggle favorite, press F to see your favorites">
            <heart-on-icon v-show="isFavorite === true"></heart-on-icon>
            <heart-off-icon v-show="isFavorite === false"></heart-off-icon>
        </button>
    </div>
</template>

<script>
    import VueClipDirective from "../directives/clip";
    import FlipIcon from "@fortawesome/fontawesome-free/svgs/solid/sync.svg?inline";
    import HeartOffIcon from "@fortawesome/fontawesome-free/svgs/regular/heart.svg?inline";
    import HeartOnIcon from "@fortawesome/fontawesome-free/svgs/solid/heart.svg?inline";

    import {getLocal, setLocal} from "../local.js";

    let favorites = getLocal("home.colorPairs.favorites", []);

    export default {
        props: {
            colors: {},
            gradient: {type: Boolean, default: false},
        },

        components: {FlipIcon, HeartOffIcon, HeartOnIcon},

        directives: {
            clip: VueClipDirective,
        },

        data() {
            const fg = this.colors[0];
            const bg = this.colors[1];
            const index = favorites.findIndex(cp => (cp.fg === fg && cp.bg === bg));

            return {
                fg: fg,
                bg: bg,
                isFavorite: index !== -1,
            };
        },

        methods: {
            flip() {
                const t = this.fg;
                this.fg = this.bg;
                this.bg = t;
            },

            favorite() {
                const index = favorites.findIndex(cp => (cp.fg === this.fg && cp.bg === this.bg));


                if (index === -1) {
                    favorites.push({fg: this.fg, bg: this.bg});
                } else {
                    favorites.splice(index, 1);
                }

                this.isFavorite = index === -1;
                setLocal("home.colorPairs.favorites", favorites);
            },
        },

        computed: {
            styles() {
                return this.gradient ? {
                    "color": this.fg,
                    "background-image": `linear-gradient(to right, ${this.bg}, ${this.fg})`,
                } : {
                    "color": this.fg,
                    "background-color": this.bg,
                };
            },

            css() {
                return `color: ${this.fg}; background-color: ${this.bg}`;
            },
        },
    };
</script>

<style lang="less" scoped>
    .color-pair {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Cabin, "Comic Sans MS", monospace;
        position: relative;

        &__color {
            font-size: min(2rem, 2.5vw);
            font-weight: bold;
            text-transform: uppercase;
        }

        &__button {
            border: 0 none;
            text-transform: uppercase;
            padding: 0.5rem 1rem;
            border-radius: 0.125rem;
            display: inline-flex;
            margin-top: 1rem;
            cursor: pointer;
            line-height: 1;
            justify-content: center;
            align-items: center;

            svg {
                fill: currentColor;
                width: 1rem;
                height: 1rem;
            }
        }

        &__save {
            display: none;
            position: absolute;
            bottom: 2rem;
            left: 2rem;
            border: 1px solid transparent;
            background-color: transparent;
            border-radius: 999px;
            width: 2rem;
            height: 2rem;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                border: 1px solid currentColor;
            }

            svg {
                fill: currentColor;
                width: 1rem;
                position: relative;
                top: 1px;
            }
        }

        &:hover &__save {
            display: flex;
        }
    }
</style>
