var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-page" }, [
    _c("h1", [_vm._v("ifcanduela.com")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "app-grid" },
      [
        _vm._l(_vm.apps, function (app) {
          return _c(
            "a",
            { staticClass: "app card", attrs: { href: app.url } },
            [
              _c("div", { staticClass: "card__thumbnail" }, [
                _c("img", { attrs: { src: app.thumbnail, alt: app.name } }),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "card__title" }, [
                _vm._v(_vm._s(app.name)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card__description" }, [
                _vm._v(_vm._s(app.description)),
              ]),
            ]
          )
        }),
        _vm._v(" "),
        _vm._l(_vm.appsies, function (app) {
          return _c(
            "a",
            {
              staticClass: "app card card--no-image",
              attrs: { href: app.url },
            },
            [
              _c("h2", { staticClass: "card__title" }, [
                _vm._v(_vm._s(app.name)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card__description" }, [
                _vm._v(_vm._s(app.description)),
              ]),
            ]
          )
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "app card card--no-image",
        attrs: { href: "https://pew.ifcanduela.com/" },
      },
      [_c("h2", { staticClass: "card__title" }, [_vm._v("Pew-pew-pew")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "app card card--no-image",
        attrs: {
          href: "https://www.github.com/ifcanduela/webpack-boilerplate",
        },
      },
      [
        _c("h2", { staticClass: "card__title" }, [
          _vm._v("Webpack boilerplate"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }