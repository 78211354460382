<template>
    <div class="ui-app">
        <div class="ui-container">
            <div class="ui-page-title">
                <h1 class="ui-page-title__title">Wishlist</h1>
            </div>

            <div class="ui-panel max-w-40 mar-t-3 mar-b-3">
                <div class="ui-filter">
                    <input type="text" class="ui-filter__input" v-model="filter">
                    <button class="ui-filter__clear-button" @click="filter = ''" :disabled="!filter">&times;</button>
                </div>

                <div class="ui-list">
                    <div class="ui-block" v-for="g in results" :key="g.id">
                        <div>
                            <img class="thumbnail" :src="`/wishlist-thumbnails/img_${g.id}.png`" alt="">
                            {{ g.name }}
                        </div>
                        <span>{{g.price.toLocaleString("es-ES")}} €</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import "../../../../automata-ui/ui.less";

    export default {
        data() {
            return {
                games: [],
                filter: "",
            };
        },

        computed: {
            results() {
                if (this.filter) {
                    const rx = new RegExp(this.filter.replace(/\s+/, "(.+)"), "i");

                    return this.games.filter(g => rx.test(g.name));
                }
                return this.games;
            },
        },

        created() {
            this.$axios("/api/wishlist/index")
                .then(r => {
                    this.games = r.data.games;
                })
        },
    };
</script>

<style scoped>
    .ui-app {
        overflow: hidden;
    }

    .thumbnail {
        margin: -1rem;
        margin-right: 1rem;
        width: 30px;
        height: 30px;
        opacity: 0.5;
        border: 1px solid var(--dark);
        outline: 2px solid var(--dark);
        outline-offset: 2px;
        position: relative;
        top: -5px;
    }

    .ui-block:hover .thumbnail {
        z-index: 2;
    }
</style>
