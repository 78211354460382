var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-page" }, [
    _c(
      "div",
      {
        staticClass: "dropzone",
        on: {
          dragover: function ($event) {
            $event.preventDefault()
            return _vm.dragOver.apply(null, arguments)
          },
          dragleave: function ($event) {
            $event.preventDefault()
            return _vm.dragLeave.apply(null, arguments)
          },
          dragenter: function ($event) {
            $event.preventDefault()
            return _vm.dragEnter.apply(null, arguments)
          },
          dragExit: function ($event) {
            $event.preventDefault()
            return _vm.dragExit.apply(null, arguments)
          },
          drop: function ($event) {
            $event.preventDefault()
            return _vm.drop.apply(null, arguments)
          },
        },
      },
      [
        _c("h1", [_vm._v("Hello, this is the Pixel component.")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dragging,
                expression: "dragging",
              },
            ],
            staticClass: "dropzone-notice",
          },
          [_c("div", [_vm._v("Drop it here")])]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col col--form" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "block_size" } }, [
                _vm._v("Block size"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.blockSize,
                      expression: "blockSize",
                    },
                  ],
                  attrs: { type: "text", id: "block_size" },
                  domProps: { value: _vm.blockSize },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.blockSize = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "target_size" } }, [
                _vm._v("Target size"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.targetSize,
                      expression: "targetSize",
                    },
                  ],
                  attrs: { type: "text", id: "target_size" },
                  domProps: { value: _vm.targetSize },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.targetSize = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.imageFile,
                  expression: "imageFile",
                },
              ],
              staticClass: "col",
            },
            [
              _c("div", { staticClass: "result" }, [
                _vm._v(
                  _vm._s(_vm.result) +
                    " [" +
                    _vm._s(_vm.resultWidth) +
                    " × " +
                    _vm._s(_vm.resultHeight) +
                    "]"
                ),
              ]),
              _vm._v(" "),
              _c("canvas", { ref: "canvas", attrs: { id: "canvas" } }),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }