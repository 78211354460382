function store(key, value) {
    if (typeof value !== "undefined") {
        return window.localStorage.setItem(key, JSON.stringify(value));
    }

    return window.localStorage.getItem(key);
}

function getLocal(key, defaultValue) {
    const data = window.localStorage.getItem(key);

    if (data !== null) {
        return JSON.parse(data);
    }

    if (typeof defaultValue !== "undefined") {
        return defaultValue;
    }

    return null;
}
function setLocal(key, value) {
    console.log({key, value});
    const data = JSON.stringify(value);
    window.localStorage.setItem(key, data);
}

function hasLocal(key) {
    const data = window.localStorage.getItem(key);

    return data !== null;
}

export {getLocal, setLocal, hasLocal};
