var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-page" }, [
    _c("div", { staticClass: "panel" }, [
      _c("h1", [_vm._v("Lehmer pseudo-random number generator")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "seed-input" }, [
        _c("label", { attrs: { for: "seed" } }, [_vm._v("Seed:")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.seed,
              expression: "seed",
            },
          ],
          attrs: { id: "seed" },
          domProps: { value: _vm.seed },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.seed = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("button", { on: { click: _vm.nextNumber } }, [_vm._v("Next")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "current" }, [_vm._v(_vm._s(_vm.current))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _c("p", [
        _vm._v(
          "\n            A simple and fast PRNG to produce sequences of random-looking numbers based on a seed. Entering the same\n            seed and clicking "
        ),
        _c("strong", [_vm._v("Next")]),
        _vm._v(
          " will always produce the same sequence of numbers.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("For more information, check the "),
        _c(
          "a",
          {
            attrs: {
              href: "https://en.m.wikipedia.org/wiki/Lehmer_random_number_generator",
            },
          },
          [_vm._v("Wikipedia article")]
        ),
        _vm._v("."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }