var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-page" }, [
    _c("div", { staticClass: "panel" }, [
      _c("div", [
        _c("label", { attrs: { for: "base" } }, [_vm._v("Body font-size")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.base,
              expression: "base",
            },
          ],
          attrs: { name: "base", id: "base", type: "text" },
          domProps: { value: _vm.base },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.base = $event.target.value
            },
          },
        }),
        _vm._v("px\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "inputs" }, [
        _c("div", { staticClass: "from" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.source,
                expression: "source",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.source },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.source = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.from))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "to" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "text", disabled: "", readonly: "" },
            domProps: { value: _vm.result },
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.to))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("button", { on: { click: _vm.toggleUnits } }, [
          _vm._v("Flip units"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "from" } }, [_vm._v("From")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("label", { attrs: { for: "to" } }, [_vm._v("To")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }