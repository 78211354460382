<template>
    <div class="vue-page">
        <div class="panel">
            <div>
                <label for="base">Body font-size</label> <input name="base" id="base" type="text" v-model="base">px
            </div>

            <div class="inputs">
                <div class="from">
                    <div><label for="from">From</label></div>
                    <input type="text" v-model="source">
                    <span>{{from}}</span>
                </div>

                <div class="to">
                    <div><label for="to">To</label></div>
                    <input type="text" disabled readonly :value="result">
                    <span>{{to}}</span>
                </div>
            </div>
            <div>
                <button @click="toggleUnits">Flip units</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                base: 16,
                source: 16,
                from: "px",
                to: "rem",
            };
        },

        methods: {
            toggleUnits() {
                const src = this.result;

                [this.from, this.to] = [this.to, this.from];

                this.source = src;
            },
        },

        computed: {
            result() {
                if (this.from === "px") {
                    return this.source / this.base;
                } else {
                    return this.source * this.base;
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Staatliches&display=swap');

    @grey: #e5e5e5;
    @blue: #14213d;
    @orange: #fca311;

    .vue-page {
        background-color: #e5e5e5;
        background-color: #e5e5e5;
        font-family: "Open Sans", sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }

    .panel {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 24rem;
        background-color: #fff;
        padding: 3rem;
    }

    .inputs {
        display: flex;

        > div {
            flex: 1;
        }
    }

    label {
        text-transform: uppercase;
        color: @blue;
    }

    input {
        font-size: 2rem;
        padding: 0.25rem;
        width: 6rem;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.05);
        color: #14213D;
    }

    button {
        font-family: "Staatliches";
        font-size: 28px;
        color: rgba(0, 0, 0, 0.75);
        background-color: @orange;
        border: 0 none;
        padding: 1rem;
        cursor: pointer;
        width: 100%;
    }

    .vue-page {
        background-color: #e5e5e5;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2314213d' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
</style>
