<template>
    <div class="vue-page">
        <main-menu prefix="trophy-appeal"></main-menu>

        <div class="page">
            <div class="page-title">
                <h1>Trophy appeal calculator <span @click="showDetails = !showDetails">?</span></h1>
                <div class="help" v-show="showDetails">
                    <p>The Appeal Index&trade; is calculated in this way:</p>
                    <ol>
                        <li>
                            A first factor (<code>p</code>) is calculated by adding together 2 values:
                            <ul>
                                <li>2 points for each heart</li>
                                <li>3 points if the game has a Platinum Trophy</li>
                            </ul>
                        </li>
                        <li>Factor <code>p</code> will be a number between 2 (1 heart, no platinum) to 13 (5 hearts and platinum trophy)</li>
                        <li>
                            A second factor (<code>n</code>) is calculated by adding together two values:
                            <ul>
                                <li>Difficulty, using this formula: <code>ln(max(1, difficulty - 3) * 5)</code></li>
                                <li>Time to complete, using this formula: <code>ln(time)</code></li>
                            </ul>
                        </li>
                        <li>The final index is computed by dividing both factors:</li>
                        <li><code>(p / n) * 100</code></li>
                    </ol>
                </div>
            </div>

            <div class="toolbar">
                <div class="toolbar-group">
                    <div class="filter-input">
                        <input type="text" v-model="nameFilter" placeholder="Filter...">
                        <button v-show="nameFilter" @click="nameFilter = ''">&times;</button>
                    </div>

                    <div class="checkbox">
                        <input type="checkbox" id="only-plat" v-model="onlyPlat">
                        <label for="only-plat">Only Plat</label>
                    </div>
                </div>

                <div class="toolbar-group">
                    <button @click="openGameForm()">Add game</button>
                </div>
            </div>

            <table cellspacing="0" cellpadding="0">
                <thead>
                    <tr>
                        <th @click="updateSort('name')" :class="[...sortClass('name')]">
                            <span>Name</span>
                        </th>
                        <th @click="updateSort('length')" :class="[...sortClass('length')]">
                            <span>Length</span>
                        </th>
                        <th @click="updateSort('appeal')" :class="[...sortClass('appeal')]" width="20%">
                            <span>Appeal</span>
                        </th>
                        <th @click="updateSort('index')" width="12%" :class="[sortClass('index')]">
                            <span>Index</span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(g, index) in display" class="game" :key="g.uuid">
                        <td colspan="2">
                            <div class="game-index">{{(index + 1).toString(10).padStart(3, "0")}}</div>
                            <div class="game-name">
                                <span>{{g.name}}</span>

                                <game-dropdown>
                                    <a @click.prevent="openGameForm('edit', g)" href="#">Edit</a>
                                    <a @click.prevent="deleteGame(g)" href="#`">Delete</a>
                                    <a :href="searchLink(g)">Trophy Guide</a>
                                </game-dropdown>
                            </div>

                            <div class="game-info">
                                <span class="has-platinum" :class="[`has-platinum--${g.hasPlatinum}`]"><!--{{ g.hasPlatinum ? "✅" : "❎" }}--> Platinum</span>
                                &mdash;
                                <span class="time-to-platinum">{{ g.timeToPlatinum }} hours</span>,
                                {{ g.platinumDifficulty }}<span class="platinum-difficulty--out-of">/10</span>
                            </div>
                        </td>

                        <td>{{ "♥".repeat(g.appeal) }}<span class="appeal--out-of">{{ "♥".repeat(5-g.appeal) }}</span></td>

                        <td><span class="appeal-index">{{ g.index }}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="screen" v-if="showGameForm">
            <div class="modal">
                <form @submit.prevent="submitForm" action="/" method="post">
                    <div class="form-group">
                        <label for="gameForm__name">Name</label>
                        <div><input id="gameForm__name" type="text" v-model="gameForm.name"></div>
                    </div>

                    <div class="form-group checkbox">
                        <input type="checkbox" id="gameForm_hasPlatinum" v-model="gameForm.hasPlatinum">
                        <label for="gameForm_hasPlatinum">Has Platinum Trophy</label>
                    </div>

                    <div class="form-group">
                        <label for="gameForm_appeal">Appeal (1 to 5)</label>
                        <div><input id="gameForm_appeal" type="text" v-model="gameForm.appeal"></div>
                    </div>

                    <div class="form-group">
                        <label for="gameForm_platinumDifficulty">Platinum difficulty (1 to 10)</label>
                        <div><input id="gameForm_platinumDifficulty" type="text" v-model="gameForm.platinumDifficulty"></div>
                    </div>

                    <div class="form-group">
                        <label for="gameForm_timeToPlatinum">Estimated time to 100% (in hours)</label>
                        <div><input id="gameForm_timeToPlatinum" type="text" v-model="gameForm.timeToPlatinum"></div>
                    </div>

                    <div class="form-group form-actions">
                        <a href="#" @click.prevent="closeGameForm">Cancel</a>
                        <button type="submit">Add game</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    const GameDropdown = {
        template: `<div class="game-dropdown" dropdown-1234="">
            <button class="game-dropdown__button" @click="toggle">...</button>
            <div class="game-dropdown__screen" v-show="open" @click.self="toggle"></div>
            <div class="game-dropdown__popup" v-show="open">
                <slot></slot>
            </div>
        </div>`,

        data() {
            return {
                open: false,
            };
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },
        },
    };

    function gameForm(name = "") {
        return {
            uuid: null,
            name: name,
            hasPlatinum: true,
            appeal: 5,
            platinumDifficulty: 5,
            timeToPlatinum: 10,
        };
    }

    export default {
        components: {GameDropdown},

        data() {
            return {
                games: [],
                sortField: "index",
                sortDirection: 1,
                appealMultiplier: 2,
                hasPlatinumMultiplier: 3,
                nameFilter: "",
                onlyPlat: false,
                sortFields: {
                    name: {defaultSortDirection: 1},
                    appeal: {defaultSortDirection: -1},
                    index: {defaultSortDirection: -1},
                    length: {defaultSortDirection: 1},
                },
                gameForm: gameForm(),
                gameFormMode: null,
                showGameForm: false,
                showDetails: false,
            };
        },

        methods: {
            updateSort(field) {
                if (this.sortField === field) {
                    this.sortDirection *= -1;
                } else {
                    this.sortField = field;
                    this.sortDirection = 1;
                }
            },

            sortClass(field) {
                const classes = ["sortable"];

                if (this.sortField === field) {
                    classes.push("active");

                    if (this.sortDirection === this.sortFields[this.sortField].defaultSortDirection) {
                        classes.push("asc");
                    } else {
                        classes.push("desc");
                    }
                }

                return classes;
            },

            calculatedIndex(g) {
                const plat = g.hasPlatinum * this.hasPlatinumMultiplier;
                const appeal = g.appeal * this.appealMultiplier;
                const pros = plat + appeal;

                const diff = Math.log(Math.max(1, g.platinumDifficulty - 3) * 5);
                const time = Math.log(g.timeToPlatinum);
                const cons = diff + time;

                return Math.round(1000 * pros / cons) / 10;
            },

            searchLink(g) {
                const s = encodeURIComponent(`${g.name} trophy guide`);
                return `https://duckduckgo.com/?q=${s}&ia=web`;
            },

            submitForm() {
                if (this.gameFormMode === "new") {
                    this.addGame();
                } else {
                    this.updateGame();
                }
            },

            addGame(addAnother = false) {
                const game = {
                    name: this.gameForm.name,
                    hasPlatinum: this.gameForm.hasPlatinum ? 1 : 0,
                    appeal: parseInt(this.gameForm.appeal),
                    platinumDifficulty: parseInt(this.gameForm.platinumDifficulty),
                    timeToPlatinum: parseInt(this.gameForm.timeToPlatinum),
                };

                this.$axios.post("/api/trophy-appeal/add", {game})
                    .then(r => {
                        this.games.push(r.data.game);
                    })

                this.closeGameForm();
            },

            updateGame() {
                const game = {
                    uuid: this.gameForm.uuid,
                    name: this.gameForm.name,
                    hasPlatinum: this.gameForm.hasPlatinum ? 1 : 0,
                    appeal: parseInt(this.gameForm.appeal),
                    platinumDifficulty: parseInt(this.gameForm.platinumDifficulty),
                    timeToPlatinum: parseInt(this.gameForm.timeToPlatinum),
                };

                this.$axios.post(`/api/trophy-appeal/update/${game.uuid}`, {game})
                    .then(r => {
                        const game = r.data.game;
                        const k = this.games.findIndex(g => g.uuid === game.uuid);
                        this.games.splice(k, 1, game);
                    })

                this.closeGameForm();
            },

            deleteGame(game) {
                this.$axios.get(`/api/trophy-appeal/delete/${game.uuid}`)
                    .then(r => {
                        if (r.data) {
                            console.log(this.games[0].uuid, game.uuid);
                            this.games = this.games.filter(g => g.uuid != game.uuid);
                        }
                    });
            },

            openGameForm(mode = "new", game = null) {
                if (game) {
                    this.gameForm = game;
                } else {
                    this.gameForm = gameForm();
                }

                this.gameFormMode = mode;
                this.showGameForm = true;
            },

            closeGameForm() {
                this.gameFormMode = null;
                this.showGameForm = false;
                this.gameForm = gameForm();
            },
        },

        computed: {
            display() {
                let list = this.games.slice(0);

                if (this.nameFilter) {
                    const rx = new RegExp(this.nameFilter, "i");

                    list = list.filter(g => rx.test(g.name));
                }

                list.forEach(g => {
                    if (!g.index) {
                        g.index = this.calculatedIndex(g);
                    }
                });

                if (this.onlyPlat) {
                    list = list.filter(g => g.hasPlatinum);
                }

                if (this.sortField === "name") {
                    list.sort((a, b) => a.name.localeCompare(b.name));
                } else if (this.sortField === "index") {
                    list.sort((a, b) => b.index - a.index);
                } else if (this.sortField === 'appeal') {
                    list.sort((a, b) => b.appeal - a.appeal);
                } else if (this.sortField === "length") {
                    list.sort((a, b) => a.timeToPlatinum - b.timeToPlatinum);
                }

                if (this.sortDirection === -1) {
                    list.reverse();
                }

                return list;
            },
        },

        created() {
            this.$axios("/api/trophy-appeal/index")
                .then(r => {
                    this.games = r.data.games.filter(g => g.appeal).map(g => {
                        g.index = this.calculatedIndex(g);
                        return g;
                    });
                });
        },
    };
</script>

<style lang="less" scoped>
    @import (less) "../../fonts/cabin/cabin.css";

    .vue-page {
        background-color: #fff;
        color: #000;
        font-family: Cabin, sans-serif;
        font-size: 100%;
        min-height: 100vh;
        font-size: 22px;
    }

    .page {
        min-height: 100vh;
        width: 48rem;
        background-color: #fff;
        margin: 0 auto;
        padding: 1.75rem;
        line-height: 1.5;
    }

    .toolbar {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
    }

    .toolbar-group {
        display: flex;
        gap: 0.5rem;
    }

    .page-title {
        margin-bottom: 1rem;

        h1 {
            margin: 0;
            padding: 0;
            font-size: 2rem;
            font-weight: bold;

            span {
                cursor: pointer;
                position: relative;
                top: -10px;
                padding: 1px 6px;
                background-color: #000;
                color: #fff;
                font-weight: bold;
                font-size: 0.75rem;
            }
        }
    }

    .help {
        border: 3px solid black;
        border-left-style: none;
        border-right-style: none;

        p {
            margin-top: 0;
        }
    }

    h1, h2 {
        font-size: 1rem;
        text-transform: uppercase;
    }

    h1 {
        font-weight: bold;
    }

    h2 {
        font-weight: normal;
        text-decoration: underline;
    }

    span {
        // background-color: #000;
    }

    table {
        width: 100%;

        thead {
            th {
                border-bottom: 4px solid #000;
                border-top: 4px solid #000;
                font-weight: bold;
                font-style: italic;
                text-align: left;
            }
        }

        th,
        td {
            padding: 0.5rem 0;
        }
    }

    .game {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 1.5rem;
            left: -1.2rem;
            height: 2px;
            width: 1rem;
            background-color: black;
        }
    }

    .game-index {
        position: absolute;
        top: 13px;
        left: -53px;
        font-size: 0.45rem;
        font-weight: bold;
        letter-spacing: 0.07em;
        text-align: right;
        width: 50px;
    }

    .game-name {
        font-weight: bold;

        a {
            text-decoration: none;
            color: #000;
        }
    }

    .game-info {
        font-size: 0.75rem;
    }

    .has-platinum {
        &::before {
            content: "";
            display: inline-block;
            width: 1em;
            height: 1em;
            border: 2px solid black;
            position: relative;
            top: 1px;
        }

        &.has-platinum--1 {
            &::before {
                background-color: #000;
            }
        }
    }

    .appeal--out-of,
    .platinum-difficulty--out-of {
        opacity: 0.25;
    }

    .appeal-index {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem 0.75rem;
        background-color: #000;
        color: #fff;
        font-weight: bold;
        font-size: 1.1rem;
    }

    .sortable {
        span {
            position: relative;
            cursor: pointer;
        }

        span::after {
            position: absolute;
            left: calc(100% + 0.25rem);
            color: #000;
            font-size: 0.75rem;
            top: 5px;
        }

        &.active {
            &.desc {
                span::after { content: "▼"; }
            }

            &.asc {
                span::after { content: "▲"; }
            }
        }
    }

    .filter-input {
        display: inline-flex;
        position: relative;

        input[type=text] {
            background-color: #000;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            font-weight: bold;
            min-width: 20rem;
            border: 0;
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 100%;
            border: 0;
            background-color: #000;
            color: #fff;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                color: yellow;
            }
        }
    }

    .toolbar {
        display: flex;
        gap: 1rem;
    }

    .checkbox {
        position: relative;

        input {
            position: absolute;
            opacity: 0;
        }

        input + label {
            cursor: pointer;

            &:before {
                content: "";
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border: 1px solid black;
                background-color: transparent;
                transition: background-color 1.5s cubic-bezier(0, 1, 0.5, 1);
                margin-right: 0.5rem;
            }
        }

        input:checked + label {
            &:before {
                background-color: black;
            }
        }
    }

    .screen {
        position: fixed;
        background-color: #fffa;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        z-index: 1020;

        .modal {
            background-color: #fff;
            padding: 3rem;
            border: 4px solid #000;

            .form-group {
                margin-bottom: 1rem;
            }

            .form-actions {
                margin-bottom: 0;
                display: flex;
                gap: 1.5rem;
                justify-content: flex-end;
                align-items: center;
            }

            input[type=text] {
                background-color: #000;
                color: #fff;
                padding: 0.5rem;
                font-weight: bold;
                min-width: 20rem;
                border: 0;
            }

            a {
                color: #000;
                opacity: 0.5;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 0.8rem;
                font-weight: bold;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    button {
        border: 2px solid #000;
        cursor: pointer;
        padding: 0.5rem 1rem;
        background-color: #fff;
        text-transform: uppercase;
        font-weight: bold;
    }
</style>

<style lang="less">
    .game-dropdown[dropdown-1234] {
        display: inline-flex;
        position: relative;
        z-index: 999;

        .game-dropdown__button {
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }

        .game-dropdown__screen {
            position: fixed;
            background-color: transparent;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .game-dropdown__popup {
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 100%;
            background-color: white;
            justify-content: flex-start;
            align-items: flex-start;

            a {
                font-size: 0.9rem;
                padding: 0.25rem 0.5rem;
                display: block;
                background-color: black;
                color: white;
                white-space: nowrap;

                &:hover {
                    // background-color: white;
                    color: yellow;
                }
            }
        }
    }

    .trophy-appeal--main-menu {
        position: absolute;
        top: 10px;
        left: 10px;

        .menu__toggle {
            display: flex;
            flex-direction: column;
            gap: 4px;
            cursor: pointer;

            span {
                display: block;
                width: 16px;
                height: 3px;
                background-color: #000;
            }
        }

        .menu__list {
            display: flex;
            flex-direction: column;
            transform-origin: top left;
        }

        .menu__item {
            color: #000;
            text-decoration: none;
            font-size: 0.75rem;
            padding: 0.25rem 1rem 0.25rem 0;

            + .menu__item {
                border-top: 1px solid #000;
            }
        }
    }

    .app_menu-enter-active,
    .app_menu-leave-active {
        transition: all 0.15s ease-in-out;
    }

    .app_menu-enter,
    .app_menu-leave-to {
        opacity: 0;
        transform: translateY(-25px);
    }
</style>
