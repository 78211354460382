import Vue from "vue";
import axios from "./axios";
import url from "./url";

Vue.prototype.$axios = axios;
Vue.prototype.$url = url;

import Base64EncoderPage from "./pages/Base64EncoderPage";
import ColorPairsPage from "./pages/ColorPairsPage";
import DottiePage from "./pages/DottiePage";
import DownloadAllPage from "./pages/DownloadAllPage";
import FalloutPasswordCrackerPage from "./pages/FalloutPasswordCrackerPage";
import FalloutPasswordGamePage from "./pages/FalloutPasswordGamePage";
import GoogleFontsPage from "./pages/GoogleFontsPage";
import GradientsPage from "./pages/GradientsPage";
import HomePage from "./pages/HomePage";
import PixelPage from "./pages/PixelPage";
import RandomSequencesPage from "./pages/RandomSequencesPage";
import RemToPxPage from "./pages/RemToPxPage";
import TrophyAppealPage from "./pages/TrophyAppealPage";
import WishlistPage from "./pages/WishlistPage";

import MainMenu from "./components/MainMenu";

Vue.$baseUrl = document.querySelector("meta[name=base-url]").content;

Vue.component("main-menu", MainMenu);

new Vue({
    el: "#app",

    components: {
        Base64EncoderPage,
        ColorPairsPage,
        DottiePage,
        DownloadAllPage,
        FalloutPasswordCrackerPage,
        FalloutPasswordGamePage,
        GoogleFontsPage,
        GradientsPage,
        HomePage,
        PixelPage,
        RandomSequencesPage,
        RemToPxPage,
        TrophyAppealPage,
        WishlistPage,
    },
});
